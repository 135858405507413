import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { getMessages } from "../../redux/actionCreator";
import { messageLists, messageLists__status, messageLists_count } from "../../redux/commonReducer";
const Messages = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const message_lists = useSelector(messageLists);

  const pageCount = useSelector(messageLists_count);
  const pageStatus = useSelector(messageLists__status);

  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(false);

  const linkArray = [
    {
      title: "Contact Messages",
      link: null,
    },
  ];

  useEffect(() => {
    dispatch(getMessages());
  }, []);

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("page", e.selected);
      dispatch(getMessages(formData));
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("keyword", data?.keyword);
    dispatch(getMessages(formData));
  };

  const onSortHandler = (type) => {
    setSortOrder(!sortOrder);
    setSortColumn(type);
    const formData = new FormData();
    // formData.append("user_status", status);
    formData.append("page", page);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortOrder ? 1 : -1);
    dispatch(getMessages(formData));
  };
  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Contact Messages" linkArray={linkArray} />
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="default__heading">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="forms">
                        <div className="search ml-0">
                          <div className="input-group">
                            <input
                              type="text"
                              {...register("keyword")}
                              className="form-control"
                              placeholder="Search here"
                            />
                            <button className="btn btn-primary" type="submit">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div id="banners_data" className="dataTables_wrapper">
                      {!pageCount == 0 ? (
                        <table
                          className="display w-100 dataTable table table-striped"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                            <th
                                onClick={() => onSortHandler("name")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "name" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "name" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >Name</th>
                              <th
                                onClick={() => onSortHandler("email")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "email" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "enail" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >Email</th>
                              <th
                                onClick={() => onSortHandler("title")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "title" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "title" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >Title</th>
                               <th
                                onClick={() => onSortHandler("message")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "message" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "message" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >Message</th>
                              <th className="sorting">DateTime</th>
                            </tr>
                          </thead>

                          <tbody>
                            {message_lists?.map((ele, i) => {
                              return (
                                <tr className="odd" role="row">
                                  <td>{ele?.name}</td>
                                  <td>{ele?.email}</td>
                                  <td>{ele?.title}</td>
                                  <td style={{width:"600px"}}>{ele?.message}</td>
                                  <td style={{whiteSpace:"nowrap"}}>
                                    <span className="mr-2">
                                      {new Date(
                                      ele?.createdAt
                                    ).toDateString()}</span>
                                     {new Date(
                                      ele?.createdAt
                                    ).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="noData-found">
                          <h3>No Data Listed!</h3>
                        </div>
                      )}

                     
                    </div>
                  </div>
                   <div className="paginationStyle mt-3">
                    <p>{pageStatus}</p>
                        {pageCount > 1 ? (
                          <ReactPaginate
                            previousLabel={<>❮</>}
                            nextLabel={<>❯</>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            // breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            initialPage={page}
                            onPageChange={(e) => handlePageChange(e)}
                            containerClassName="pagination"
                            activeClassName="pageActive"
                          />
                        ) : null}
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Messages;
