import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import JoditReact from "jodit-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { getAbout, insertAbout, updateAbout } from "../../redux/actionCreator";
import { API_BASE_IMAGE_URL, editorConfig, editorConfigAra } from "../../constants/configuration";
import { about_details, about_path } from "../../redux/commonReducer";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";

function About() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const aboutDetail = useSelector(about_details);
  const aboutPath = useSelector(about_path);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const linkArray = [
    {
      title: "About",
      link: null,
    },
  ];

  useEffect(() => {
    dispatch(
      getAbout((data) => {
        reset({
          titleen: data?.title_en,
          titlear: data?.title_ar,
          detail_en: data?.detail_en,
          detail_ar: data?.detail_ar,
        });
      })
    );
  }, []);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("banner_name", data?.banner[0]);
    formData.append("image_name", data?.image[0]);
    formData.append("icon_name", data?.icon[0]);
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("detail_en", data?.detail_en);
    formData.append("detail_ar", data?.detail_ar);
    dispatch(
      insertAbout(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          dispatch(getAbout());
          toast.success("About added successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const handleUpdate = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("banner_name", data?.banner[0]);
    formData.append("image_name", data?.image[0]);
    formData.append("icon_name", data?.icon[0]);
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("detail_en", data?.detail_en);
    formData.append("detail_ar", data?.detail_ar);
    formData.append("id", aboutDetail._id);

    dispatch(
      updateAbout(formData, (res) => {
        dispatch(getAbout());
        if (res === true) {
          setIsSubmitting(false);
          toast.success("About Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  return (

    <>
      <>
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="About" linkArray={linkArray} />
              <div className="col-xl-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <form
                        onSubmit={
                          aboutDetail
                            ? handleSubmit(handleUpdate)
                            : handleSubmit(onSubmit)
                        }
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              {aboutDetail ? (
                                <>
                                  <div className="col-md-4">
                                    {imagePreview.banner ? (
                                      <div className="preview__img">
                                        <img
                                          src={imagePreview.banner}
                                          className="preview"
                                          alt="Preview"
                                        />
                                      </div>
                                    ) : (
                                      <div className="preview__img">
                                        <img
                                          src={
                                            API_BASE_IMAGE_URL +
                                            aboutPath +
                                            "/" +
                                            aboutDetail?.banner_name
                                          }
                                          className="preview"
                                          alt=""
                                        />
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {imagePreview.banner && (
                                    <div className="col-md-4">
                                      <div className="preview__img">
                                        <img
                                          src={imagePreview.banner}
                                          className="preview"
                                          alt="Preview"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {aboutDetail ? (
                                <div className="col-md-4 pl-0">
                                  {imagePreview.image ? (
                                    <div className="preview__img">
                                      <img
                                        src={imagePreview.image}
                                        className="preview"
                                        alt="Preview"
                                      />
                                    </div>
                                  ) : (
                                    <div className="preview__img">
                                      <img
                                        src={
                                          API_BASE_IMAGE_URL +
                                          aboutPath +
                                          "/" +
                                          aboutDetail?.image_name
                                        }
                                        className="preview"
                                        alt=""
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  {imagePreview.image && (
                                    <div className="col-md-4 pl-0">
                                      <div className="preview__img">
                                        <img
                                          src={imagePreview.image}
                                          className="preview"
                                          alt="Preview"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {aboutDetail ? (
                                <div className="col-md-4 pl-0">
                                  {imagePreview.icon ? (
                                    <div className="preview__img">
                                      <img
                                        src={imagePreview.icon}
                                        className="preview"
                                        alt="Preview"
                                      />
                                    </div>
                                  ) : (
                                    <div className="preview__img">
                                      <img
                                        src={
                                          API_BASE_IMAGE_URL +
                                          aboutPath +
                                          "/" +
                                          aboutDetail?.icon_name
                                        }
                                        className="preview"
                                        alt=""
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  {imagePreview.icon && (
                                    <div className="col-md-4 pl-0">
                                      <div className="preview__img">
                                        <img
                                          src={imagePreview.icon}
                                          className="preview"
                                          alt="Preview"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Banner <span className="resolution">(image size : 1110x330)</span></label>
                              <input
                                type="file"
                                className="form-control"
                                {...register("banner", {
                                  required: aboutDetail ? false : true,
                                })}
                                placeholder="logo"
                                onChange={(e) => handleImagePreview(e)}
                              />
                              {errors.banner?.type === "required" && (
                                <span className="formError">
                                  Banner Image is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Image <span className="resolution">(image size : 570x854)</span></label>
                              <input
                                type="file"
                                className="form-control"
                                {...register("image", {
                                  required: aboutDetail ? false : true,
                                })}
                                placeholder="logo"
                                onChange={(e) => handleImagePreview(e)}
                              />
                              {errors.image?.type === "required" && (
                                <span className="formError">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Icon <span className="resolution">(image size : 100x100)</span></label>
                              <input
                                type="file"
                                className="form-control"
                                {...register("icon", {
                                  required: aboutDetail ? false : true,
                                })}
                                placeholder="logo"
                                onChange={(e) => handleImagePreview(e)}
                              />
                              {errors.icon?.type === "required" && (
                                <span className="formError">
                                  Icon is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (en)</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("titleen", {
                                  required: aboutDetail ? false : true,
                                })}
                                placeholder="type here"
                              />
                              {errors.titleen?.type === "required" && (
                                <span className="formError">
                                  Title (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (ar)</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("titlear", {
                                  required: aboutDetail ? false : true,
                                })}
                                placeholder="type here"
                              />
                              {errors.titlear?.type === "required" && (
                                <span className="formError">
                                  Title (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (en)</label>
                              <Controller
                                {...register("detail_en", {
                                  required: aboutDetail ? false : true,
                                })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfig}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.detail_en?.type === "required" && (
                                <span className="formError">
                                  Detail (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (ar)</label>
                              <Controller
                                {...register("detail_ar", {
                                  required: aboutDetail ? false : true,
                                })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    direction="rtl"
                                    config={editorConfigAra}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    className="arabic"
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.detail_ar?.type === "required" && (
                                <span className="formError">
                                  Detail (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        {aboutDetail ? (
                          <button
                            type="submit"
                            className="btn btn-primary mr-1"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Updating..." : "Update"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary mr-1"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                        )}

                        <button
                          className="btn btn-danger"
                          onClick={(e) => navigate('/')}
                        >
                          Cancel
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
}

export default About;
