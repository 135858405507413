import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";
import {
  getMessages,
  getModules,
  insertUsers,
} from "../../redux/actionCreator";
import { modules } from "../../redux/commonReducer";
import { useState } from "react";

function AddUsers() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const module_list = useSelector(modules);

  const linkArray = [
    {
      title : "Users",
      link : "/users"
    },
    {
      title : "Add User",
      link : null
    }
  ]

  useEffect(() => {
    dispatch(getModules());
  }, []);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("name", data.full_name);
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("email", data.email);
    // formData.append("phone", data.phone);
    formData.append("user_status", data.status);
    // formData.append("profile_image", data.image[0]);
    data.modules?.forEach((item,i) => {
      formData.append(`permissions[]`, item);
    });
    console.log(formData)
    dispatch(
      insertUsers(formData, (res) => {
        if(res===false){
          setIsSubmitting(false);
        }else{
          navigate("/users")
        }
       
      })
    );
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title = "Add User" linkArray = {linkArray}/>
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Full name"
                              {...register("full_name", { required: true })}
                            />
                            {errors.full_name?.type === "required" && (
                              <span className="formError">
                                Full name is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Username</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                              autoFocus="off"
                              autocomplete="off"
                              {...register("username", { required: true })}
                            />
                            {errors.username?.type === "required" && (
                              <span className="formError">
                                User name is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              autocomplete="new-password"
                              {...register("password", { required: true })}
                            />
                            {errors.password?.type === "required" && (
                              <span className="formError">
                                Password is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              })}
                            />
                            {errors.email?.type === "required" && (
                              <span className="formError">
                                Email is required
                              </span>
                            )}
                            {errors.email?.type === "pattern" && (
                              <span className="formError">
                                Please type valid email
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone Number"
                              {...register("phone", {
                                required: true,
                                pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                              })}
                            />
                            {errors.phone?.type === "required" && (
                              <span className="formError">
                                Phone Number is required
                              </span>
                            )}
                          </div>
                        </div> */}
                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <label>Image</label>
                            <input
                              type="file"
                              className="form-control"
                              placeholder="Image"
                              {...register("image", {
                                required: true,
                              })}
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {errors.image?.type === "required" && (
                              <span className="formError">
                                Image is required
                              </span>
                            )}
                            {imagePreview.image ? (
                              <div className="preview__img">
                                <img
                                  src={imagePreview.image}
                                  className="preview"
                                  alt="Preview"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Status</label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="1"
                                defaultChecked
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Active
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="0"
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Inactive
                              </label>
                            </div>
                            <br />
                            {errors.status?.type === "required" && (
                              <span className="formError">
                                Please select status
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            {module_list?.map((ele, i) => {
                              return (
                                <div className="col-md-3">
                                  <div className="form-group">
                                    {/* <label className="mb-4">
                                      <strong>{ele.module_name}</strong>
                                    </label>
                                    <br /> */}
                                    {/* {ele?.module_options?.map((e, j) => {
                                      return ( */}
                                        <div className="form-check custom-checkbox mb-3 checkbox-primary">
                                          <input
                                            type="checkbox"
                                            defaultChecked
                                            className="form-check-input"
                                            id={i}
                                            name={ele.module_slug}
                                            value={ele._id}
                                            {...register(`modules`, {
                                              required: false,
                                            })}
                                          />
                                          <label
                                            className="form-check-label"
                                            for={i}
                                          >
                                            {ele.module_name}
                                          </label>
                                        </div>
                                      {/* );
                                    })} */}
                                    <br />
                                    {/* {Object.keys(errors).includes(ele._id) && (
                                      <span className="formError">
                                        Permission is required
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary mr-1" disabled={isSubmitting}>
                        {isSubmitting ? 'submitting...' : 'Submit'}
                      </button>
                      <button type="submit" className="btn btn-danger" onClick={()=>navigate("/users")}>
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddUsers;
