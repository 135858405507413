import React from "react";
import { useSelector } from "react-redux";
import { translate_details } from "../redux/commonReducer";

function NotFound() {
  return (
    <div className="Notfound">
      <h1>
        {"Page Not Found"}
      </h1>
    </div>
  );
}

export default NotFound;
