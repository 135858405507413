import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { API_BASE_IMAGE_URL, webUrl } from "../../constants/configuration";
import {
  deleteRolesAssign,
  getRolesAssign,
  getUserPermissions,
  updateRolesAssignStatus,
} from "../../redux/actionCreator";
import {
  permissions,
  roleslist_count,
  roles_list,
  roles_path,
  roleslist_status,
} from "../../redux/commonReducer";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";

const Roles = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rolesLists = useSelector(roles_list);
  const rolesPath = useSelector(roles_path);

  // DELETE
  const [show, setShow] = useState(false);
  const closeBtn = () => setShow(false);
  const [deleteid, setDeleteID] = useState("");
  const [statusID, setStatusID] = useState("");
  const [page, setPage] = useState(0);
  
  const pageCount = useSelector(roleslist_count);
  const pageStatus = useSelector(roleslist_status);

  const [status, setStatus] = useState(false);

  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(false);

  const linkArray = [
    {
      title: "Roles & Assignments",
      link: null,
    },
  ];

  const handleShow = (ele) => {
    setShow(true);
    setDeleteID(ele._id);
  };

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);

  const handleClose = () => {
    const id = {
      id: deleteid,
    };
    dispatch(
      deleteRolesAssign(id, (res) => {
        if (res === true) {
          dispatch(getRolesAssign());
          toast.success("Roles & Assigneleted successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
    setShow(false);
  };

  useEffect(() => {
    dispatch(getRolesAssign());
  }, []);

  const handleUpdatestatus = (ele) => {
    setStatus(true);
    setStatusID(ele._id);
  };

  const handleStatusChange = () => {
    const id = statusID;
    dispatch(
      updateRolesAssignStatus(id, (res) => {
        if (res === true) {
          dispatch(getRolesAssign());
          toast.success("Status Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
    setStatus(false);
  };

  const editHandler = (ele) => {
    navigate(`/edit-roles/${ele._id}`);
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("page", e.selected);
      dispatch(getRolesAssign(formData));
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("keyword", data?.keyword);
    formData.append("status", data?.status);
    dispatch(getRolesAssign(formData));
  };

  const onSortHandler = (type) => {
    setSortOrder(!sortOrder);
    setSortColumn(type);
    const formData = new FormData();
    // formData.append("user_status", status);
    formData.append("page", page);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortOrder ? 1 : -1);
    dispatch(getRolesAssign(formData));
  };

  return (
    <>
      {/* DELETE */}
      <Modal show={show} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to delete?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={closeBtn}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleClose}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* UPDATE */}
      <Modal show={status} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to update status?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={() => setStatus(false)}>
            No
          </button>
          <button className="btn btn-primary" onClick={handleStatusChange}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Roles & Assignments" linkArray={linkArray} />

            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="default__heading">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="forms">
                        <div className="form-group">
                          <select
                            {...register("status")}
                            className="form-control form-select"
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                        <div className="search">
                          <div className="input-group">
                            <input
                              type="text"
                              {...register("keyword")}
                              className="form-control"
                              placeholder="Search here"
                            />
                            <button className="btn btn-primary" type="submit">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="button">
                      <Link to="/add-roles">
                        <button className="btn btn-primary">
                          Add Roles/Assignments
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div id="banners_data" className="dataTables_wrapper">
                      {!pageCount == 0 ? (
                        <table
                          className="display w-100 dataTable table table-striped"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting_asc">Image</th>
                              <th className="sorting">Icon Title</th>
                              <th className="sorting">Icon Body</th>
                              <th className="sorting">Banner</th>
                              <th
                                onClick={() => onSortHandler("title_en")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "title_en" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "title_en" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >
                                Title (en)
                              </th>
                              <th
                                onClick={() => onSortHandler("title_ar")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "title_ar" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "title_ar" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >
                                Title (ar)
                              </th>
                              <th
                                onClick={() => onSortHandler("sub_title_en")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "sub_title_en" &&
                                sortOrder == true
                                  ? "active"
                                  : sortColumn == "sub_title_en" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >
                                SubTitle (en)
                              </th>
                              <th
                                onClick={() => onSortHandler("sub_title_ar")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "sub_title_ar" &&
                                sortOrder == true
                                  ? "active"
                                  : sortColumn == "sub_title_ar" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >
                                SubTitle (ar)
                              </th>
                              <th
                                onClick={() => onSortHandler("detail_en")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "detail_en" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "detail_en" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >
                                Detail (en)
                              </th>
                              <th
                                onClick={() => onSortHandler("detail_ar")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "detail_ar" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "detail_ar" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >
                                Detail (ar)
                              </th>
                              <th
                                onClick={() => onSortHandler("active")}
                                className={`sorting cursor-pointer 
                              ${
                                sortColumn == "active" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "active" && sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                              >
                                Status
                              </th>
                              <th className="sorting">Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {rolesLists?.map((ele, i) => {
                              return (
                                <tr className="odd" role="row" key={i}>
                                  <td>
                                    <img
                                      className="td-img"
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath+
                                        "/" +
                                        ele?.image_name
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <img
                                      className="td-img"
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath+
                                        "/" +
                                        ele?.title_icon_name
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <img
                                      className="td-img"
                                      style={{backgroundColor:'#e3e3e3',padding:'5px'}}
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath+
                                        "/" +
                                        ele?.body_icon_name
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <img
                                      className="td-img"
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath+
                                        "/" +
                                        ele?.banner_name
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <td>{ele.title_en}</td>
                                  <td>{ele.title_ar}</td>
                                  <td>{ele.sub_title_en}</td>
                                  <td>{ele.sub_title_ar}</td>
                                  <td>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.detail_en,
                                      }}
                                    ></p>
                                  </td>
                                  <td>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.detail_ar,
                                      }}
                                    ></p>
                                  </td>
                                  <td>
                                    {ele.active === 1 ? (
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() => handleUpdatestatus(ele)}
                                      >
                                        Active
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => handleUpdatestatus(ele)}
                                      >
                                        Inactive
                                      </button>
                                    )}
                                  </td>

                                  <td style={{ minWidth: "200px" }}>
                                    <button
                                      className="btn btn-sm btn-table"
                                      onClick={() => editHandler(ele)}
                                    >
                                      <img
                                        src="./assets/img/table-edit.png"
                                        alt=""
                                      />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-table"
                                      onClick={() => handleShow(ele)}
                                    >
                                      <img
                                        src="./assets/img/table-delete.png"
                                        alt=""
                                      />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="noData-found">
                          <h3>No Data Listed!</h3>
                        </div>
                      )}
                      <div className="paginationStyle">
                        <p>{pageStatus}</p>
                        {pageCount > 1 ? (
                          <ReactPaginate
                            previousLabel={<>❮</>}
                            nextLabel={<>❯</>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            // breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            initialPage={page}
                            onPageChange={(e) => handlePageChange(e)}
                            containerClassName="pagination"
                            activeClassName="pageActive"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Roles;
