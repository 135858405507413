import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { API_BASE_IMAGE_URL, editorConfig, editorConfigAra } from "../../constants/configuration";
import {
  getHomeBannerdetail,
  insertHomeBanners,
  updateHomeBanners,
} from "../../redux/actionCreator";
import { homebanner_details, homebanner_path } from "../../redux/commonReducer";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";
import JoditReact from "jodit-react";
import { Controller, useForm } from "react-hook-form";
function HomeBanner() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const bannerDetail = useSelector(homebanner_details);
  const homeBannerPath = useSelector(homebanner_path);
  // const bannerDetail = null

  const linkArray = [
    {
      title: "Home Banners",
      link: null,
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("image_name", data?.logo[0]);
    formData.append("detail_en", data?.detail_en);
    formData.append("detail_ar", data?.detail_ar);
    formData.append("url_en", data?.url_en);
    formData.append("url_ar", data?.url_ar);
    dispatch(
      insertHomeBanners(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          dispatch(
            getHomeBannerdetail((data) => {
              reset({
                titleen: data?.title_en,
                titlear: data?.title_ar,
                detail_en: data?.detail_en,
                detail_ar: data?.detail_ar,
                url_en: data?.url_en,
                url_ar: data?.url_ar,
              });
            })
          );
          toast.success("Home content successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getHomeBannerdetail((data) => {
        reset({
          titleen: data?.title_en,
          titlear: data?.title_ar,
          detail_en: data?.detail_en,
          detail_ar: data?.detail_ar,
          url_en: data?.url_en,
          url_ar: data?.url_ar,
        });
      })
    );
  }, []);

  const onUpdate = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("image_name", data?.logo[0]);
    formData.append("detail_en", data?.detail_en);
    formData.append("detail_ar", data?.detail_ar);
    formData.append("url_en", data?.url_en);
    formData.append("url_ar", data?.url_ar);
    formData.append("id", bannerDetail?._id);

    dispatch(
      updateHomeBanners(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          dispatch(
            getHomeBannerdetail((data) => {
              reset({
                titleen: data?.title_en,
                titlear: data?.title_ar,
                detail_en: data?.detail_en,
                detail_ar: data?.detail_ar,
                url_en: data?.url_en,
                url_ar: data?.url_ar,
              });
            })
          );
          toast.success("Home content updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };
  const dontSubmit = (e) => {
    setIsSubmitting(false);
    e.preventDefault();
    navigate('/')
  };
  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Home Banners" linkArray={linkArray} />
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form
                      onSubmit={
                        bannerDetail
                          ? handleSubmit(onUpdate)
                          : handleSubmit(onSubmit)
                      }
                    >
                      <div className="row">
                        {
                          <>
                            {imagePreview.logo ? (
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="preview__img">
                                      <img
                                        src={imagePreview.logo}
                                        className="preview"
                                        alt="Preview"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                {bannerDetail && (
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="preview__img">
                                          <img
                                            src={
                                              API_BASE_IMAGE_URL +
                                              homeBannerPath +
                                              "/" +
                                              bannerDetail?.image_name
                                            }
                                            className="preview"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        }
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Logo <span className="resolution">(image size : 300x300)</span></label>
                            <input
                              type="file"
                              {...register("logo", {
                                required: bannerDetail ? false : true,
                              })}
                              className="form-control"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {errors.logo?.type === "required" && (
                              <span className="formError">
                                Logo is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Title (en)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titleen", { required: true })}
                              placeholder="type here"
                            />
                            {errors.titleen?.type === "required" && (
                              <span className="formError">
                                Title (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Title (ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titlear", { required: true })}
                              placeholder="type here"
                            />
                            {errors.titlear?.type === "required" && (
                              <span className="formError">
                                Title (ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Detail (en)</label>
                            <Controller
                              {...register("detail_en", { required: true })}
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfig}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                />
                              )}
                            />
                            {errors.detail_en?.type === "required" && (
                              <span className="formError">
                                Detail (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Detail (ar)</label>
                            <Controller
                              {...register("detail_ar", { required: true })}
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfigAra}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                  className="arabic"
                                />
                              )}
                            />
                            {errors.detail_ar?.type === "required" && (
                              <span className="formError">
                                Detail (ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>URL (ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("url_ar", { required: false })}
                              placeholder="type here"
                            />
                            {errors.url_ar?.type === "required" && (
                              <span className="formError">
                                URL (ar) is required
                              </span>
                            )}
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>URL (en)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("url_en", { required: false })}
                              placeholder="type here"
                            />
                            {errors.url_en?.type === "required" && (
                              <span className="formError">
                                URL (en) is required
                              </span>
                            )}
                          </div>
                        </div> */}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        disabled={isSubmitting}
                      >
                        {bannerDetail
                          ? isSubmitting
                            ? "Updating..."
                            : "Update"
                          : isSubmitting
                          ? "Submitting..."
                          : "Submit"}
                      </button>
                      <button className="btn btn-danger" onClick={dontSubmit}>
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default HomeBanner;
