import React, { useEffect, useState } from 'react';
import SortableItem from './SortableItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const SortableList = ({ setUploadedFiles,uploadedFiles, handleDelete, API_BASE_IMAGE_URL, randomTrackID }) => {
  const [items, setItems] = useState(uploadedFiles);
  useEffect(() => {
    setItems(uploadedFiles)
  }, [uploadedFiles])
  

  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    // console.log({updatedItems})
    setItems(updatedItems);
    setUploadedFiles([])
    setUploadedFiles(updatedItems)
  };
 

  return (
    console.log(randomTrackID,'randomTrackID'),
    <DndProvider backend={HTML5Backend}>
      <div className="grid-container">
        {items.map((file, index) => (
          <SortableItem
            key={file._id}
            index={index}
            file={file}
            moveItem={moveItem}
            handleDelete={handleDelete}
            API_BASE_IMAGE_URL={(!file.track_id) ? API_BASE_IMAGE_URL+'news/' : API_BASE_IMAGE_URL+'temp_files/'+file.track_id+'/'}
            randomTrackID={randomTrackID}
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default SortableList;