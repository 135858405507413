import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { API_BASE_IMAGE_URL, webUrl } from "../../constants/configuration";
import {
    changeBannertype,
    deleteBanner,
    deleteNews,
    getBanners,
    getBannertype,
    getNews,
    getTagline,
    getUserPermissions,
    insertTagline,
    updateBannerstatus,
    updateTagline,
} from "../../redux/actionCreator";
import {
    bannersLists,
    banner_count,
    permissions,
    banner_path,
    newsList,
    newsPages_count,
    newsPages_status,
} from "../../redux/commonReducer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import { Spinner } from "react-bootstrap";
import moment from "moment";

const News = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();
    const [show, setShow] = useState(false);
    const [statusShow, setStatusShow] = useState(false);
    const [deleteid, setDeleteID] = useState("");
    const [statusid, setStatusID] = useState("");
    const [page, setPage] = useState(0);
    const [bannerType, setBannerType] = useState("")
    const [tagLineInputs, setTagLineInputs] = useState({})
    const [tagLoader, setTagLoader] = useState(false)
    const [keyword, setKeyword] = useState("");

    const [taglineToggle, setTagLineToggle] = useState(false)

    const [sortColumn, setSortColumn] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState(false);

    const pageCount = useSelector(newsPages_count);
    const page_status = useSelector(newsPages_status);

    const linkArray = [
        {
            title: "News",
            link: null,
        },
    ];

    const handleShow = (ele) => {
        setShow(true);
        setDeleteID(ele._id);
    };

    const closeBtn = () => setShow(false);
    const handleClose = () => {
        dispatch(
            deleteNews(deleteid, (res) => {
                if (res === true) {
                    dispatch(getNews());
                    toast.success("News Deleted successfully", {
                        position: "bottom-center",
                        autoClose: 3000,
                    });
                }
            })
        );
        setShow(false);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const newItems = useSelector(newsList);
    const bannerPath = useSelector(banner_path);
    useEffect(() => {
        dispatch(getNews());
        dispatch(getBannertype(res => {
            setBannerType(res)
        }))
    }, []);

    useEffect(() => {
        dispatch(getUserPermissions());
    }, []);

    const editHandler = (ele) => {
        ele.banner_type === "video" ? navigate(`/edit-video-banners/${ele._id}`) : navigate(`/edit-banners/${ele._id}`);
    };

    const handleStatusChange = (ele) => {
        setStatusShow(true);
        setStatusID(ele._id);
    };

    const handleStatus = () => {
        dispatch(
            updateBannerstatus(statusid, (res) => {
                if (res === true) {
                    setStatusShow(false);
                    dispatch(getBanners());
                    toast.success("Status updated successfully", {
                        position: "bottom-center",
                        autoClose: 3000,
                    });
                }
            })
        );
    };

    const handlePageChange = (e) => {
        setPage(e.selected);
        if (e.selected >= 0) {
            let formData = new FormData();
            formData.append("sort_key", sortColumn);
            formData.append("sort_order", sortOrder ? 1 : -1);
            formData.append("page", e.selected);
            dispatch(getNews(formData));
        }
    };

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("keyword", data?.keyword);
        formData.append("status", data?.status);
        dispatch(getBanners(formData));
    };

    const onSortHandler = (type) => {
        setSortOrder(!sortOrder);
        setSortColumn(type);
        const formData = new FormData();
        // formData.append("user_status", status);
        formData.append("page", page);
        formData.append("sort_key", type);
        formData.append("sort_order", !sortOrder ? 1 : -1);
        dispatch(getNews(formData));
    };

    const toggleBannerType = val => {
        dispatch(changeBannertype(res => {
            toast.success("Banner Type changed successfully", {
                position: "bottom-center",
                autoClose: 3000
            })
            setBannerType(res.data.data)
            dispatch(getBanners());
            setSortColumn("createdAt")
            setSortOrder(false)
        }))
        // setBannerType(val ? "video" : "image")
    }

    const showAddButton = () => {
        if (bannerType === "image") {
            return true
        } else {
            if (newItems && newItems?.length < 1) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        dispatch(getTagline((res) => {
            setTagLineInputs({
                id: res.tagline?._id,
                title_en: res.tagline?.tagline_en,
                title_ar: res.tagline?.tagline_ar,
            })
        }))
    }, [])

    function taglineSubmitHandler() {
        setTagLoader(true)
        const formdata = new FormData()
        formdata.append('tagline_en', tagLineInputs.title_en)
        formdata.append('tagline_ar', tagLineInputs.title_ar)
        if (tagLineInputs.id) {
            formdata.append('id', tagLineInputs.id)
            dispatch(updateTagline(formdata, () => {
                setTagLoader(false)
                setTagLineToggle(false)
            }))
        } else {
            dispatch(insertTagline(formdata, () => {
                setTagLoader(false)
                setTagLineToggle(false)
            }))
        }
    }

    const onSearchHandler = () => {
        const formData = new FormData();
        formData.append("keyword", keyword);
        dispatch(getNews(formData));
    };

    const onCancelHandler = () => {
        setKeyword("");
        dispatch(getNews());
    };


    return (
        <>
            {/* DELETE */}
            <Modal show={show} centered className="deleteModal">
                <Modal.Body>
                    <h2>Do you want to delete?</h2>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={closeBtn}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={handleClose}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>

            {/* STATUS UPDATE */}
            <Modal show={statusShow} centered className="deleteModal">
                <Modal.Body>
                    <h2>Do you want to update status?</h2>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-danger"
                        onClick={() => setStatusShow(false)}
                    >
                        No
                    </button>
                    <button className="btn btn-primary" onClick={handleStatus}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>

            <Header />
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <PageTitle title="News" linkArray={linkArray} />
                        {bannerType ? <div className="col-12">

                            <div className="card">
                                <div className="search d-flex justify-content-between">
                                    <div className="input-group" style={{ width: 500 }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Here"
                                            value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)}
                                        />
                                        <button
                                            className="btn btn-primary ml-3"
                                            type="button"
                                            onClick={() => onSearchHandler()}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="btn btn-danger ml-2"
                                            type="button"
                                            onClick={() => onCancelHandler()}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                    <div className="button d-flex align-self-end mb-4" style={{ gap: 10 }}>
                                        <Link onClick={() => setTagLineToggle(s => !s)}>
                                            <button className="btn btn-primary" >Tagline</button>
                                        </Link>
                                        <Link to={'/news/add_news'}>
                                            <button className="btn btn-primary" >Add News</button>
                                        </Link>
                                    </div>
                                </div>

                                {
                                    taglineToggle &&
                                    <div className="card">
                                        <div style={{ width: '100%', marginBottom: '20px' }} className="add_tag_line">
                                            <label htmlFor="" style={{ width: "100%" }}>
                                                Tagline (en)
                                                <input type="text" className="form-control" style={{ width: "100%" }} value={tagLineInputs.title_en}
                                                    onChange={(e) => setTagLineInputs(s => ({ ...s, title_en: e.target.value }))}
                                                />
                                            </label>
                                            <br />
                                            <label htmlFor="" style={{ width: "100%" }}>
                                                Tagline (ar)
                                                <input type="text" className="form-control" style={{ width: "100%" }} value={tagLineInputs.title_ar}
                                                    onChange={(e) => setTagLineInputs(s => ({ ...s, title_ar: e.target.value }))}
                                                />
                                            </label>
                                            <button className="btn btn-primary" onClick={taglineSubmitHandler}>{tagLoader ? 'Submitting' : 'Submit'}</button>
                                        </div>
                                    </div>
                                }
                                {/* <div className="card-header">
                                    <div className="default__heading">

                                    </div>
                                </div> */}
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="banners_data" className="dataTables_wrapper">
                                            {
                                                true
                                                    // !pageCount == 0
                                                    ? (
                                                        <table
                                                            className="display w-100 dataTable table table-striped"
                                                            id="example5"
                                                            role="grid"
                                                            aria-describedby="example5_info"
                                                        >
                                                            <thead>
                                                                <tr role="row">
                                                                    <th className="sorting_asc">Image</th>
                                                                    <th style={{ width: 150 }}
                                                                        onClick={() => onSortHandler("date")}
                                                                        className={`sorting cursor-pointer 
                           ${sortColumn == "date" && sortOrder == true
                                                                                ? "active"
                                                                                : sortColumn == "date" &&
                                                                                    sortOrder == false
                                                                                    ? "inactive"
                                                                                    : ""
                                                                            }`}
                                                                    >Publish Date</th>
                                                                    <th
                                                                        onClick={() => onSortHandler("title_en")}
                                                                        className={`sorting cursor-pointer 
                              ${sortColumn == "title_en" && sortOrder == true
                                                                                ? "active"
                                                                                : sortColumn == "title_en" &&
                                                                                    sortOrder == false
                                                                                    ? "inactive"
                                                                                    : ""
                                                                            }`}
                                                                    >
                                                                        Title (En)
                                                                    </th>
                                                                    <th
                                                                        onClick={() => onSortHandler("title_ar")}
                                                                        className={`sorting  cursor-pointer
                              ${sortColumn == "title_ar" && sortOrder == true
                                                                                ? "active"
                                                                                : sortColumn == "title_ar" &&
                                                                                    sortOrder == false
                                                                                    ? "inactive"
                                                                                    : ""
                                                                            }`}
                                                                    >
                                                                        Title (Ar)
                                                                    </th>
                                                                    {/* <th
                                                                onClick={() => onSortHandler("url_en")}
                                                                className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''}
                              ${sortColumn == "url_en" && sortOrder == true
                                                                        ? "active"
                                                                        : sortColumn == "url_en" && sortOrder == false
                                                                            ? "inactive"
                                                                            : ""
                                                                    }`}
                                                            >
                                                                Url (En)
                                                            </th>
                                                            <th
                                                                onClick={() => onSortHandler("url_ar")}
                                                                className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''} 
                              ${sortColumn == "url_ar" && sortOrder == true
                                                                        ? "active"
                                                                        : sortColumn == "url_ar" && sortOrder == false
                                                                            ? "inactive"
                                                                            : ""
                                                                    }`}
                                                            >
                                                                Url (Ar)
                                                            </th> */}
                                                                    {/* {bannerType === "image" ? <th
                                                                        onClick={() => onSortHandler("active")}
                                                                        className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''} 
                              ${sortColumn == "active" && sortOrder == true
                                                                                ? "active"
                                                                                : sortColumn == "active" && sortOrder == false
                                                                                    ? "inactive"
                                                                                    : ""
                                                                            }`}
                                                                    >
                                                                        Status
                                                                    </th> : null} */}
                                                                    <th className="sorting">Actions</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {newItems?.map((ele, i) => {
                                                                    return (
                                                                        <tr className={"odd" + (ele.is_published === 0 ? ' published' : '')} role="row">
                                                                            <td>
                                                                                <img
                                                                                    className="td-img"
                                                                                    src={
                                                                                        API_BASE_IMAGE_URL +
                                                                                        'news' +
                                                                                        "/" +
                                                                                        ele.thumbnail
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </td>
                                                                            <td>{moment(ele.news_date).format('DD MMM YYYY')}</td>
                                                                            <td>{ele.title_en}</td>
                                                                            <td>{ele.title_ar}</td>
                                                                            {/* <td>{ele.banner_type === "video" ? ele.video_url : ele.url_en}</td>
                                                                    <td>{ele.banner_type === "video" ? ele.video_url_ar : ele.url_ar}</td> */}
                                                                            {/* {bannerType === "image" ? <td>
                                                                                {ele.active === 1 ? (
                                                                                    <button
                                                                                        className="btn btn-sm btn-success"
                                                                                        onClick={() => handleStatusChange(ele)}
                                                                                    >
                                                                                        Active
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        className="btn btn-sm btn-danger"
                                                                                        onClick={() => handleStatusChange(ele)}
                                                                                    >
                                                                                        Inactive
                                                                                    </button>
                                                                                )}
                                                                            </td> : null} */}

                                                                            <td>
                                                                                <button
                                                                                    className="btn btn-sm btn-table"
                                                                                    onClick={() => navigate('/news/edit_news/' + ele._id)}
                                                                                >
                                                                                    {" "}
                                                                                    <img
                                                                                        src="./assets/img/table-edit.png"
                                                                                        alt=""
                                                                                    />{" "}
                                                                                </button>
                                                                                {/* {bannerType === "image" ? <button */}
                                                                                {true ? <button
                                                                                    className="btn btn-sm btn-table"
                                                                                    onClick={() => handleShow(ele)}
                                                                                >
                                                                                    <img
                                                                                        src="./assets/img/table-delete.png"
                                                                                        alt=""
                                                                                    />
                                                                                </button> : null}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div className="noData-found">
                                                            <h3>No Data Listed!</h3>
                                                        </div>
                                                    )}

                                            <div className="paginationStyle">
                                                <p>{page_status}</p>
                                                {pageCount > 1 ? (
                                                    <ReactPaginate
                                                        previousLabel={<>❮</>}
                                                        nextLabel={<>❯</>}
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        // breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={5}
                                                        initialPage={page}
                                                        onPageChange={(e) => handlePageChange(e)}
                                                        containerClassName="pagination"
                                                        activeClassName="pageActive"
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                            <div className="d-flex w-100 align-items-center justify-content-center h-100" style={{ minHeight: 500 }}>
                                <Spinner style={{ color: "#0a0f31" }}></Spinner>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default News;
