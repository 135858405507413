import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { API_BASE_IMAGE_URL } from "../../constants/configuration";
import { getBannerdetails, updateBanners } from "../../redux/actionCreator";
import { banner_details, banner_path } from "../../redux/commonReducer";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";

function EditBanners() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bannerDetail = useSelector(banner_details);
  const bannerPath = useSelector(banner_path);
  const [titleen, setTitleen] = useState("");
  const [titlear, setTitlear] = useState("");
  const [urlen, setUrlen] = useState("");
  const [urlar, setUrlar] = useState("");
  const [status, setStatus] = useState("");
  const [image, setImage] = useState(null);

  const linkArray = [
    {
      title: "Banners",
      link: "/banners",
    },
    {
      title: "Edit Banners",
      link: null,
    },
  ];

  useEffect(() => {
    if (bannerDetail) {
      setTitleen(bannerDetail?.title_en);
      setTitlear(bannerDetail?.title_ar);
      setUrlen(bannerDetail?.url_en);
      setUrlar(bannerDetail?.url_ar);
      setStatus(bannerDetail?.active);
    }
  }, [bannerDetail]);

  useEffect(() => {
    if (id) {
      dispatch(getBannerdetails(id));
    }
  }, [id]);

  const handleFileChange = (e) => {
    handleImagePreview(e);
    setImage(e.target.files[0]);
  };

  const handleUpdate = (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("title_en", titleen);
    formData.append("title_ar", titlear);
    formData.append("image_name", image);
    formData.append("url_en", urlen);
    formData.append("url_ar", urlar);
    formData.append("active", status);
    formData.append("id", bannerDetail?._id);
    dispatch(
      updateBanners(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          navigate("/banners");
          toast.success("Banner Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          e.target.reset();
          navigate("/banners");
        }
      })
    );
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Edit Banners" linkArray={linkArray} />
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-4 pl-0">
                        <span className="resolution">(image size : 2160x1200)</span>
                          {imagePreview.image ? (
                            <div className="preview__img mt-2">
                              <img
                                src={imagePreview.image}
                                className="preview"
                                alt="Preview"
                              />
                            </div>
                          ) : (
                            <div className="preview__img mt-2">
                              <img
                                src={
                                  API_BASE_IMAGE_URL +
                                  bannerPath +
                                  "/" +
                                  bannerDetail?.image_name
                                }
                                className="preview"
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Image</label>
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            onChange={handleFileChange}
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Title (en)</label>
                          <input
                            type="text"
                            className="form-control"
                            value={titleen}
                            onChange={(e) => setTitleen(e.target.value)}
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Title (ar)</label>
                          <input
                            type="text"
                            className="form-control"
                            value={titlear}
                            onChange={(e) => setTitlear(e.target.value)}
                            placeholder="type here"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>URL (En)</label>
                          <input
                            type="text"
                            className="form-control"
                            value={urlen}
                            onChange={(e) => setUrlen(e.target.value)}
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>URL (Ar)</label>
                          <input
                            type="text"
                            className="form-control"
                            value={urlar}
                            onChange={(e) => setUrlar(e.target.value)}
                            placeholder="type here"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Status</label>
                          <br />
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              checked={status == "1"}
                              value={1}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Active
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              checked={status == "0"}
                              value={0}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mr-1"
                      onClick={(e) => handleUpdate(e)}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Updating..." : "Update"}
                    </button>
                    <Link to="/banners">
                      <button type="submit" className="btn btn-danger">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default EditBanners;
