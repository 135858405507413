import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const ItemType = 'ITEM';

const SortableItem = ({ file, index, moveItem, handleDelete, API_BASE_IMAGE_URL }) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    drop(draggedItem){
      // console.log(draggedItem,'draggedItem')
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    hover(draggedItem) {
      // if (draggedItem.index !== index) {
      //   moveItem(draggedItem.index, index);
      //   draggedItem.index = index;
      // }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} className="grid-item" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="closeIcon" onClick={() => handleDelete(file)}>
        <i className="mdi mdi-close"></i>
      </div>
      {file.gallery_type === 'video' ? (
        // <iframe
        //   src={file.gallery_item}
        //   frameBorder="0"
        //   allow="autoplay; encrypted-media"
        //   allowFullScreen
        //   title="video"
        //   style={{ width: '100%', height: '100px' }}
        // />
       <div className="video__prev">
         <iframe src={file.gallery_item} frameborder="0" allowfullscreen></iframe>
       </div>
      //  <div className="video"> </div>
      ) : (
        <img
          src={`${API_BASE_IMAGE_URL}${file.gallery_item}`}
          alt={`image-${index}`}
          style={{ width: '100%', height: '100px', objectFit: 'cover' }}
        />
      )}
    </div>
  );
};

export default SortableItem;