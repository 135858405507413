import React, { useEffect, useState } from 'react'
import { Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToggleDisableStatus, ToggleEnableStatus, ToggleStatus } from '../redux/actionCreator';
import { toast } from 'react-toastify';

function Header() {
  const [isActive, setisActive] = useState(false)
  const [active, setActive] = useState(false);
  const [incStatus, setincidentStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userRole = localStorage.getItem('role')

  const showPop = () => {
    setisActive(!isActive)
  }
  useEffect(() => {
    if (active) {
      document.body.classList.add('enable-vertical-menu')
    } else {
      document.body.classList.remove('enable-vertical-menu')
    }
  }, [active]);

  useEffect(() => {
    dispatch(ToggleStatus((status) => {
      setincidentStatus(status)
    }))
  }, []);

  const logOut = () => {
    toast.success("Logout successfully", {
      position: "bottom-center",
      autoClose: 3000,
    });
    localStorage.removeItem('token')
    localStorage.removeItem('permissions')
    navigate('/login')
  }

  const incidentStatus = (e) => {
    if (e.target.checked) {
      setEnable(true)
    } else {
      setDisable(true)
    }
  }

  const enableIncident = () => {
    dispatch(ToggleEnableStatus((status) => {
      setincidentStatus(status)
    }))
    setEnable(false)
  }
  const disableIncident = () => {
    dispatch(ToggleDisableStatus((status) => {
      setincidentStatus(status)
    }))
    setDisable(false)
  }

  return (
    <>
      {/* Disable*/}
      <Modal show={disable} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to disable?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={disableIncident}>
            Yes
          </button>
          <button className="btn btn-danger" onClick={() => setDisable(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
      {/* Enable */}
      <Modal show={enable} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to enable?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={enableIncident}>
            Yes
          </button>
          <button className="btn btn-danger" onClick={() => setEnable(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
      {/* LOGOUT */}
      <Modal show={show} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to logout?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={() => setShow(false)}>
            No
          </button>
          <button className="btn btn-primary" onClick={logOut}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <div className="menu-overlay"></div>
      {/* <div className="header-border" /> */}
      <header id="page-topbar">
        <div className="navbar-header justify-content-end">
          <div className="w-100 d-flex justify-content-between align-items-between">
            <button type="button" onClick={() => setActive(!active)} className="btn btn-sm mr-2 d-lg-none px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
              <i class="mdi mdi-close"></i>
            </button>
            <div className={isActive ? 'dropdown d-inline-block ml-2 show' : 'dropdown d-inline-block ml-2'}>
              <button type="button" onClick={showPop} className="btn header-item waves-effect" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="rounded-circle header-profile-user" src="./assets/img/avatar.jpg" alt="" />
                <span className="d-none d-sm-inline-block ml-1">{localStorage.getItem('username')}</span>
                <i className="mdi mdi-chevron-down d-none d-sm-inline-block" />
              </button>
              <div className={isActive ? 'dropdown-menu dropdown-menu-right show' : 'dropdown-menu dropdown-menu-right'} style={{cursor:"pointer"}}>
                <a className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => setShow(true)}>
                  <span>Log Out</span>
                </a>
              </div>
            </div>
          </div>
          {
            userRole === "super_admin" &&
            <div className="avtiveIncident">
              <p>Report Incident</p>
              <label className="incident-switch" onChange={(e) => incidentStatus(e)}>
                <input type="checkbox" checked={incStatus ? true : false} />
                <span className="switch" />
              </label>
            </div>
          }
        </div>

      </header>
    </>
  )
}

export default Header