import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { insertJurisdictionAuthorities } from "../../redux/actionCreator";
import JoditReact from "jodit-react";
import { Controller, useForm } from "react-hook-form";
import { editorConfig, editorConfigAra } from "../../constants/configuration";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";
function AddJurisdictions() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const navigate = useNavigate();

  const linkArray = [
    {
      title: "Jurisdictions & Authorities",
      link: "/jurisdictions",
    },
    {
      title: "Add Jurisdictions",
      link: null,
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("detail_en", data?.detail_en);
    formData.append("detail_ar", data?.detail_ar);

    formData.append("image_name", data?.image[0]);
    formData.append("banner_name", data?.banner[0]);
    formData.append("title_icon_name", data?.titleIcon[0]);
    formData.append("body_icon_name", data?.bodyIcon[0]);
    formData.append("active", data?.status);
    dispatch(
      insertJurisdictionAuthorities(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          navigate("/jurisdictions");
          toast.success("Role added successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle
              title="Add Jurisdictions & Authorities"
              linkArray={linkArray}
            />
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title (en)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titleen", { required: true })}
                              placeholder="Type here"
                            />
                            {errors.titleen?.type === "required" && (
                              <span className="formError">
                                Title (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title (ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titlear", { required: true })}
                              placeholder="Type here"
                            />
                            {errors.titlear?.type === "required" && (
                              <span className="formError">
                                Title (ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Detail (en)</label>
                            <Controller
                              {...register("detail_en", { required: true })}
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfig}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                />
                              )}
                            />
                            {errors.detail_en?.type === "required" && (
                              <span className="formError">
                                Detail (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Detail (ar)</label>
                            <Controller
                              {...register("detail_ar", { required: true })}
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfigAra}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                  className="arabic"
                                />
                              )}
                            />
                            {errors.detail_ar?.type === "required" && (
                              <span className="formError">
                                Detail (ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                          <div className="col-md-3">
                              {imagePreview.banner && (
                                <div className="preview__img">
                                  <img
                                    src={imagePreview.banner}
                                    className="preview"
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              {imagePreview.bodyIcon && (
                                <div className="preview__img">
                                  <img
                                    src={imagePreview.bodyIcon}
                                    className="preview"
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              {imagePreview.image && (
                                <div className="preview__img">
                                  <img
                                    src={imagePreview.image}
                                    className="preview"
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </div>
                           
                            <div className="col-md-3">
                              {imagePreview.titleIcon && (
                                <div className="preview__img">
                                  <img
                                    src={imagePreview.titleIcon}
                                    className="preview"
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </div>
                            
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Main Banner (Horizontal) <span className="resolution">(image size : 1110x330)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("banner", { required: true })}
                              placeholder="Type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.banner
                              ? errors.banner?.type === "required" && (
                                  <span className="formError">
                                    Banner Image is required
                                  </span>
                                )
                              : ""}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Banner icon for tab <span className="resolution">(image size : 100x100)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("bodyIcon", { required: true })}
                              placeholder="Type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.bodyIcon
                              ? errors.bodyIcon?.type === "required" && (
                                  <span className="formError">
                                    Body Icon is required
                                  </span>
                                )
                              : ""}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Image for description <span className="resolution">(image size : 570x570)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("image", { required: true })}
                              placeholder="Type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.image
                              ? errors.image?.type === "required" && (
                                  <span className="formError">
                                    Image is required
                                  </span>
                                )
                              : ""}
                          </div>
                        </div>
                        
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Title icon for description <span className="resolution">(image size : 100x100)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("titleIcon", { required: true })}
                              placeholder="Type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.titleIcon
                              ? errors.titleIcon?.type === "required" && (
                                  <span className="formError">
                                    Title Icon is required
                                  </span>
                                )
                              : ""}
                          </div>
                        </div>
                        
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Status</label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="1"
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Active
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="0"
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Inactive
                              </label>
                            </div>
                            <br />{" "}
                            {errors.status?.type === "required" && (
                              <span className="formError">
                                Status is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "submitting..." : "Submit"}
                      </button>
                      <Link to="/jurisdictions">
                        <button className="btn btn-danger">Cancel</button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddJurisdictions;
