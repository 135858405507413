import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUserPermissions } from "../redux/actionCreator";
import { permissions } from "../redux/commonReducer";
import { modulePermission } from "../utils/helpers";

let menus = [
  {
    id: "1",
    title: "Dashboard",
    link: "/",
    icon: "mdi mdi-home-analytics",
    permissionKey: "",
  },
  {
    id: "2",
    title: "Home",
    submenu: ["banners", "home"],
    icon: "mdi mdi-home-account",
    permissionKey: "home",
  },
  {
    id: "3",
    title: "About",
    submenu: ["about", "values", "mission"],
    icon: "mdi mdi-information",
    permissionKey: "about",
  },
  {
    id: "4",
    title: "Roles & Assignments",
    link: "/roles",
    icon: "mdi mdi-security",
    permissionKey: "roles_assignment",
  },
  {
    id: "5",
    title: "Jurisdictions & Authorities",
    link: "/jurisdictions",
    icon: "mdi mdi-shield-account",
    permissionKey: "jurisdictions_authorities",
  },
  {
    id: "6",
    title: "Contact",
    submenu: ["info", "messages"],
    icon: "mdi mdi-account-box-outline",
    permissionKey: "contact",
  },
  {
    id: "7",
    title: "Privacy Policy",
    link: "/privacy",
    icon: "mdi mdi-file-lock",
    permissionKey: "privacy",
  },
  {
    id: "8",
    title: "Terms & Conditions",
    link: "/terms",
    icon: "mdi mdi-clipboard-text",
    permissionKey: "terms_conditions",
  },
  // {
  //   id: "7",
  //   title: "Privacy",
  //   submenu: ["terms", "privacy"],
  //   icon: "mdi mdi-account-box-outline",
  //   permissionKey: "",
  // },
  {
    id: "9",
    title: "Users",
    link: "/users",
    icon: "mdi mdi-account-group",
    permissionKey: "users",
  },
  {
    id: "10",
    title: "Incident Terms",
    link: "/agreement",
    icon: "mdi mdi-file-check-outline",
    permissionKey: "incident_terms",
  },
  {
    id: "11",
    title: "News",
    link: "/news",
    icon: "mdi mdi-newspaper",
    permissionKey: "news",
  }
];

function Sidebar() {
  const [isActive, setActive] = useState("false");
  const [dropActive, setDropActive] = useState("false");
  const location = useLocation();
  const dispatch = useDispatch();

  const permission = useSelector(permissions);
  // const actionPermissions = JSON.parse(localStorage.getItem("permissions") || "[]")

  useEffect(() => {
    if (location?.pathname == "/banners") {
      setDropActive(true);
    }
  }, []);

  // useEffect(() => {
  // }, [actionPermissions,permission]);

  // useEffect(() => {
  //   dispatch(getUserPermissions());
  // }, []);

  const ToggleClass = () => {
    setActive(!isActive);
  };

  // const onPermissionHandler = (permissionKey) => {
  //   if (Array.isArray(permissionKey)) {
  //     let value = actionPermissions?.filter((e) =>
  //       permissionKey?.includes(e.module_id?.module_slug)
  //     );
  //     return value?.some(
  //       (e) => e.view == "1" || e.add == "1" || e.edit == "1" || e.delete == "1"
  //     )
  //       ? true
  //       : false;
  //   } else {
  //     let value = actionPermissions?.find(
  //       (e) => e.module_id?.module_slug == permissionKey
  //     );
  //     return value?.view == "1" ||
  //       value?.add == "1" ||
  //       value?.edit == "1" ||
  //       value?.delete == "1"
  //       ? true
  //       : false;
  //   }
  // };

  const onSubmenuPermissionHandler = (permissionKey) => { };

  return (
    (
      <>
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            <div className="navbar-brand-box">
              <Link to="/" className="logo">
                <img src="./assets/img/logo.svg" alt="" />
              </Link>
            </div>
            {/*- Sidemenu */}
            <div id="sidebar-menu">
              {/* Left Menu Start */}
              <ul className="metismenu list-unstyled" id="side-menu">
                {menus?.map((ele, i) => {
                  return (
                    <>
                      {ele.permissionKey === "" ||
                        modulePermission()?.includes(ele.permissionKey) ? (
                        <li
                          onClick={() => setActive(s => s === ele.id ? '' : ele.id)}
                          className={ele.id == isActive ? "mm-active" : ""}
                          key={i}
                          exact
                        >
                          <NavLink
                            to={ele?.link}
                            className={
                              ele?.submenu ? "has-arrow waves-effect" : ""
                            }
                          >
                            <i className={ele?.icon} />
                            <span>{ele.title}</span>
                          </NavLink>
                          {ele.submenu ? (
                            <ul
                              className={
                                ele.id == isActive
                                  ? "sub-menu"
                                  : "sub-menu mm-collapse show"
                              }
                              aria-expanded="false"
                            >
                              {ele.submenu?.map((e, j) => {
                                return (
                                  <>
                                    <li>
                                      <NavLink to={`/${e}`}>
                                        {e == "mission"
                                          ? "mission and vision"
                                          : e}
                                      </NavLink>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      ) : null}
                    </>
                  );
                })}
              </ul>
            </div>
            {/* Sidebar */}
          </div>
        </div>
      </>
    )
  );
}

export default Sidebar;
