import { Modal } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import {
  deleteAboutValues,
  getAboutValues,
  getUserPermissions,
  updateAboutValuesStatus,
} from "../../redux/actionCreator";
import {
  AboutValues,
  AboutValues_count,
  AboutValues_status,
  permissions,
} from "../../redux/commonReducer";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
const Values = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const valuesLists = useSelector(AboutValues);
  const [statusShow, setStatusShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [statusID, setStatudID] = useState("");
  const [deleteID, setDeleteID] = useState("");
  const [page, setPage] = useState(0);

  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(false);

  const pageCount = useSelector(AboutValues_count);
  const pageStatus = useSelector(AboutValues_status);

  const linkArray = [
    {
      title : "About Values",
      link : null
    }
  ]

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);

  useEffect(() => {
    dispatch(getAboutValues());
  }, []);

  const statusChange = (id) => {
    setStatusShow(true);
    setStatudID(id._id);
  };
  const handleChange = () => {
    dispatch(
      updateAboutValuesStatus(statusID, (res) => {
        if (res === true) {
          setStatusShow(false);
          dispatch(getAboutValues());
          toast.success("Status updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const handleDelete = (id) => {
    setDeleteShow(true);
    setDeleteID(id);
  };
  const deleteValue = () => {
    const id = {
      id: deleteID,
    };
    dispatch(
      deleteAboutValues(id, (res) => {
        if (res === true) {
          dispatch(getAboutValues());
          setDeleteShow(false);
          toast.success("Deleted successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };
  const handleUpdate = (id) => {
    navigate(`/edit-values/${id}`);
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("page", e.selected);
      dispatch(getAboutValues(formData));
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("keyword", data?.keyword);
    formData.append("status", data?.status);
    dispatch(getAboutValues(formData));
  };

  const onSortHandler = (type) => {
    setSortOrder(!sortOrder);
    setSortColumn(type);
    const formData = new FormData();
    // formData.append("user_status", status);
    formData.append("page", page);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortOrder ? 1 : -1);
    dispatch(getAboutValues(formData));
  };

  return (
    <>
      <>
        {/* STATUS UPDATE */}
        <Modal show={statusShow} centered className="deleteModal">
          <Modal.Body>
            <h2>Do you want to update status?</h2>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={() => setStatusShow(false)}
            >
              No
            </button>
            <button className="btn btn-primary" onClick={handleChange}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>

        {/* STATUS UPDATE */}
        <Modal show={deleteShow} centered className="deleteModal">
          <Modal.Body>
            <h2>Do you want to Delete?</h2>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={() => setDeleteShow(false)}
            >
              No
            </button>
            <button className="btn btn-primary" onClick={() => deleteValue()}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>

        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title = "About Values" linkArray = {linkArray}/>
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="default__heading">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="forms">
                          <div className="form-group">
                            <select
                              {...register("status")}
                              className="form-control form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                          <div className="search">
                            <div className="input-group">
                              <input
                                type="text"
                                {...register("keyword")}
                                className="form-control"
                                placeholder="Search here"
                              />
                              <button className="btn btn-primary" type="submit">
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="button">
                        <Link to="/add-values">
                          <button className="btn btn-primary">
                            Add Values
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* <DataTable
                      columns={columns}
                      data={valuesLists}
                      pagination
                    /> */}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div id="banners_data" className="dataTables_wrapper">
                        {!pageCount == 0 ? (
                          <table
                            className="display w-100 dataTable table table-striped"
                            id="example5"
                            role="grid"
                            aria-describedby="example5_info"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  onClick={() => onSortHandler("title_en")}
                                  className={`sorting cursor-pointer 
                              ${
                                sortColumn == "title_en" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "title_en" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                                >
                                  Title (en)
                                </th>
                                <th
                                  onClick={() => onSortHandler("title_ar")}
                                  className={`sorting cursor-pointer 
                              ${
                                sortColumn == "title_ar" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "title_ar" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                                >
                                  Title (ar)
                                </th>
                                <th
                                  onClick={() => onSortHandler("detail_en")}
                                  className={`sorting cursor-pointer 
                              ${
                                sortColumn == "detail_en" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "detail_en" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                                >
                                  Detail (en)
                                </th>
                                <th
                                  onClick={() => onSortHandler("detail_ar")}
                                  className={`sorting cursor-pointer 
                              ${
                                sortColumn == "detail_ar" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "detail_ar" &&
                                    sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                                >
                                  Detail (ar)
                                </th>
                                <th
                                  onClick={() => onSortHandler("active")}
                                  className={`sorting cursor-pointer 
                              ${
                                sortColumn == "active" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "active" && sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                                >
                                  Status
                                </th>
                                <th className="sorting">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {valuesLists?.map((ele, i) => {
                                return (
                                  <tr className="odd" role="row">
                                    <td>{ele.title_en}</td>
                                    <td>{ele.title_ar}</td>
                                    <td>{ele.detail_en}</td>
                                    <td>{ele.detail_ar}</td>
                                    <td>
                                      {ele.active === 1 ? (
                                        <button
                                          className="btn btn-sm btn-success"
                                          onClick={() => statusChange(ele)}
                                        >
                                          Active
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() => statusChange(ele)}
                                        >
                                          Inactive
                                        </button>
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-table"
                                        onClick={() => handleUpdate(ele?._id)}
                                      >
                                        <img
                                          src="./assets/img/table-edit.png"
                                          alt=""
                                        />
                                      </button>
                                      <button
                                        className="btn btn-sm btn-table"
                                        onClick={() => handleDelete(ele?._id)}
                                      >
                                        <img
                                          src="./assets/img/table-delete.png"
                                          alt=""
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="noData-found">
                            <h3>No Data Listed!</h3>
                          </div>
                        )}

                        <div className="paginationStyle">
                          <p>{pageStatus}</p>
                          {pageCount > 1 ? (
                            <ReactPaginate
                              previousLabel={<>❮</>}
                              nextLabel={<>❯</>}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              // breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              initialPage={page}
                              onPageChange={(e) => handlePageChange(e)}
                              containerClassName="pagination"
                              activeClassName="pageActive"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
};

export default Values;
