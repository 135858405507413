import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import {
  deleteUser,
  getUser,
  getUserPermissions,
  updateUserStatus,
} from "../../redux/actionCreator";
import {
  permissions,
  userCount,
  userList,
  userPageStatus,
  userPath,
} from "../../redux/commonReducer";
import ReactPaginate from "react-paginate";
import { API_BASE_URL } from "../../constants/configuration";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import moment from "moment";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector(userList);
  const path = useSelector(userPath);
  const pageCount = useSelector(userCount);
  const page_status = useSelector(userPageStatus);

  const [data, setData] = useState(
    document.querySelectorAll("#banners_data tbody tr")
  );

  const [page, setPage] = useState(0);
  const [statusShow, setStatusShow] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState("");
  const [keyword, setKeyword] = useState("");
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(false);

  const linkArray = [
    {
      title: "Users",
      link: null,
    },
  ];

  useEffect(() => {
    const formData = new FormData();
    formData.append("keyword", keyword);
    formData.append("user_status", status);
    formData.append("sort_key", "createdAt");
    formData.append("sort_order", -1);
    formData.append("page", 0);
    dispatch(getUser(formData));
  }, []);

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);
  // Active paggination & chage data

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();

      formData.append("keyword", keyword);
      formData.append("user_status", status);
      formData.append("page", e.selected);
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortOrder ? 1 : -1);
      dispatch(getUser(formData));
    }
  };

  const onEditHandler = (ele) => {
    navigate(`/edit-users/${ele._id}`);
  };

  const statusHandler = (id) => {
    setStatusShow(true);
    setStatusId(id);
  };

  const onUpdateStatusHandler = () => {
    dispatch(
      updateUserStatus(statusId, (res) => {
        if (res === true) {
          const formData = new FormData();
          formData.append("keyword", keyword);
          formData.append("user_status", status);
          formData.append("page", page);
          formData.append("sort_key", sortColumn);
          formData.append("sort_order", sortOrder ? 1 : -1);
          dispatch(getUser(formData));
          toast.success("Status Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          setStatusShow(false);
          setStatusId("");
        }
      })
    );
  };

  const onDeleteHandler = (ele) => {
    setDeleteShow(true);
    setDeleteId(ele._id);
  };

  const deleteUserHandler = () => {
    dispatch(
      deleteUser(deleteId, (res) => {
        if (res === true) {
          const formData = new FormData();
          formData.append("keyword", keyword);
          formData.append("user_status", status);
          formData.append("page", page);
          formData.append("sort_key", sortColumn);
          formData.append("sort_order", sortOrder ? 1 : -1);
          dispatch(getUser(formData));
          toast.success("User deleted successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          setDeleteShow(false);
          setDeleteId("");
        }
      })
    );
  };

  const onSearchHandler = () => {
    const formData = new FormData();
    formData.append("keyword", keyword);
    formData.append("user_status", status);
    formData.append("sort_key", "createdAt");
    formData.append("sort_order", -1);
    formData.append("page", 0);
    dispatch(getUser(formData));
  };

  const onCancelHandler = () => {
    setKeyword("");
    setStatus("");
    setSortColumn("createdAt");
    setSortOrder(false);
    setPage(0);
    const formData = new FormData();
    formData.append("keyword", "");
    formData.append("user_status", "");
    formData.append("sort_key", "createdAt");
    formData.append("sort_order", -1);
    formData.append("page", 0);
    dispatch(getUser(formData));
  };

  const onSortHandler = (type) => {
    setSortOrder(!sortOrder);
    setSortColumn(type);
    const formData = new FormData();
    formData.append("keyword", keyword);
    formData.append("user_status", status);
    formData.append("page", page);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortOrder ? 1 : -1);
    dispatch(getUser(formData));
  };

  return (
    <>
      <Modal show={statusShow} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to Update Status?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setStatusShow(false);
              setStatusId("");
            }}
          >
            No
          </button>
          <button className="btn btn-primary" onClick={onUpdateStatusHandler}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteShow} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to delete?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setDeleteShow(false);
              setDeleteId("");
            }}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={deleteUserHandler}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Users" linkArray={linkArray} />
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="default__heading">
                    <div className="forms">
                      <div className="form-group">
                        <select
                          defaultValue={"Select Status"}
                          className="form-control form-select"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option value="">Select Status</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                      </div>
                      <div className="search">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Here"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => onSearchHandler()}
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-danger"
                            type="button"
                            onClick={() => onCancelHandler()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="button">
                      <Link to="/add-users">
                        <button className="btn btn-primary">Add User</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div id="banners_data" className="dataTables_wrapper">
                      <table
                        className="display w-100 dataTable table table-striped"
                        id="example5"
                        role="grid"
                        aria-describedby="example5_info"
                      >
                        <thead>
                          <tr role="row">
                            {/* <th className="sorting_asc">Image</th> */}
                            <th
                              onClick={() => onSortHandler("name")}
                              className={`sorting cursor-pointer 
                              ${sortColumn == "name" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "name" && sortOrder == false
                                    ? "inactive"
                                    : ""
                                }`}
                            >
                              Full Name
                            </th>
                            <th
                              onClick={() => onSortHandler("username")}
                              className={`sorting cursor-pointer 
                              ${sortColumn == "username" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "username" &&
                                    sortOrder == false
                                    ? "inactive"
                                    : ""
                                }`}
                            >
                              Username
                            </th>
                            <th
                              onClick={() => onSortHandler("email")}
                              className={`sorting cursor-pointer 
                              ${sortColumn == "email" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "email" && sortOrder == false
                                    ? "inactive"
                                    : ""
                                }`}
                            >
                              Email
                            </th>
                            {/* <th
                              onClick={() => onSortHandler("phone")}
                              className={`sorting cursor-pointer 
                              ${
                                sortColumn == "phone" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "phone" && sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                            >
                              Mobile
                            </th> */}
                            <th
                              onClick={() => onSortHandler("user_type")}
                              className={`sorting cursor-pointer 
                              ${sortColumn == "user_type" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "user_type" &&
                                    sortOrder == false
                                    ? "inactive"
                                    : ""
                                }`}
                            >
                              User Type
                            </th>
                            <th
                              onClick={() => onSortHandler("user_status")}
                              className={`sorting cursor-pointer 
                              ${sortColumn == "user_status" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "user_status" &&
                                    sortOrder == false
                                    ? "inactive"
                                    : ""
                                }`}
                            >
                              Status
                            </th>
                            <th
                              onClick={() => onSortHandler("createdAt")}
                              className={`sorting cursor-pointer 
                              ${sortColumn == "createdAt" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "createdAt" &&
                                    sortOrder == false
                                    ? "inactive"
                                    : ""
                                }`}
                            >
                              Created Date
                            </th>
                            <th
                              onClick={() => onSortHandler("updatedAt")}
                              className={`sorting cursor-pointer 
                              ${sortColumn == "updatedAt" && sortOrder == true
                                  ? "active"
                                  : sortColumn == "updatedAt" &&
                                    sortOrder == false
                                    ? "inactive"
                                    : ""
                                }`}
                            >
                              Modified Date
                            </th>
                            <th className="sorting">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {/* {console.log("aslam", users)} */}
                          {users?.length ?
                            users?.map((ele, i) => {
                              return (
                                <tr className="odd" role="row">
                                  {/* <td>
                                  <img
                                    className="td-img"
                                    src={`${API_BASE_URL}${path}/${ele.profile_image}`}
                                    alt=""
                                  />
                                </td> */}
                                  <td>{ele.name}</td>
                                  <td>{ele.username}</td>
                                  <td>{ele.email}</td>
                                  {/* <td>{ele.phone}</td> */}
                                  <td>{ele.user_type}</td>
                                  <td>
                                    {ele.user_status == 1 ? (
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() => statusHandler(ele._id)}
                                      >
                                        Active
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => statusHandler(ele._id)}
                                      >
                                        Inactive
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    {moment.utc(ele?.createdAt).format('MMMM Do YYYY')}
                                  </td>
                                  <td>
                                    {moment.utc(ele?.updated_at).format('MMMM Do YYYY')}
                                  </td>
                                  <td style={{ minWidth: "150px" }}>
                                    {ele.is_current ||
                                      ele.user_type == "super_admin" ? (
                                      <button
                                        className="btn btn-sm btn-info mr-1"
                                        onClick={() => onEditHandler(ele)}
                                      >
                                        Edit
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          className="btn btn-sm btn-info mr-1"
                                          onClick={() => onEditHandler(ele)}
                                        >
                                          Edit
                                        </button>

                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() => onDeleteHandler(ele)}
                                        >
                                          Delete
                                        </button>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                            : null
                          }
                        </tbody>
                      </table>
                      {!users?.length ?
                        <div className="noData-found">
                          <h3>No Data Listed!</h3>
                        </div>
                        : null
                      }
                      <div className="paginationStyle">
                        <p>{page_status}</p>
                        {pageCount > 1 ? (
                          <ReactPaginate
                            previousLabel={<>❮</>}
                            nextLabel={<>❯</>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            // breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            initialPage={page}
                            onPageChange={(e) => handlePageChange(e)}
                            containerClassName="pagination"
                            activeClassName="pageActive"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Users;
