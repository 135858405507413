import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import JoditReact from "jodit-react";
import { editorConfig, editorConfigAra } from "../../constants/configuration";
import {
  getAgreement,
  insertAgreement,
  updateAgreement,
} from "../../redux/actionCreator";
import { agreement_details } from "../../redux/commonReducer";
import { useEffect } from "react";
import { useState } from "react";

function Introduction() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const agreementDetails = useSelector(agreement_details);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const linkArray = [
    {
      title: "Incident Terms",
      link: null,
    },
  ];

  useEffect(() => {
    dispatch(
      getAgreement((data) => {
        reset({
          incident_intro_en: data?.incident_intro_en,
          incident_intro_ar: data?.incident_intro_ar,
          incident_terms_en: data?.incident_terms_en,
          incident_terms_ar: data?.incident_terms_ar,
        });
      })
    );
  }, []);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("incident_intro_en", data?.incident_intro_en);
    formData.append("incident_intro_ar", data?.incident_intro_ar);
    formData.append("incident_terms_en", data?.incident_terms_en);
    formData.append("incident_terms_ar", data?.incident_terms_ar);
    dispatch(
      insertAgreement(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          dispatch(
            getAgreement((data) => {
              reset({
                incident_intro_en: data?.incident_intro_en,
                incident_intro_ar: data?.incident_intro_ar,
                incident_terms_en: data?.incident_terms_en,
                incident_terms_ar: data?.incident_terms_ar,
              });
            })
          );
          toast.success("Introduction & Terms added successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const onUpdate = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("incident_intro_en", data?.incident_intro_en);
    formData.append("incident_intro_ar", data?.incident_intro_ar);
    formData.append("incident_terms_en", data?.incident_terms_en);
    formData.append("incident_terms_ar", data?.incident_terms_ar);
    formData.append("id", agreementDetails?._id);
    dispatch(
      updateAgreement(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          dispatch(
            getAgreement((data) => {
              reset({
                incident_intro_en: data?.incident_intro_en,
                incident_intro_ar: data?.incident_intro_ar,
                incident_terms_en: data?.incident_terms_en,
                incident_terms_ar: data?.incident_terms_ar,
              });
            })
          );
          toast.success("Introduction & Terms Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const dontSubmit = (e) => {
    e.preventDefault();
    dispatch(
      getAgreement((data) => {
        reset({
          incident_intro_en: data?.incident_intro_en,
          incident_intro_ar: data?.incident_intro_ar,
          incident_terms_en: data?.incident_terms_en,
          incident_terms_ar: data?.incident_terms_ar,
        });
      })
    );
    navigate('/')
  };
  return (
    <>
      <>
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Incident Terms" linkArray={linkArray} />
              <div className="col-xl-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <form
                        onSubmit={
                          agreementDetails
                            ? handleSubmit(onUpdate)
                            : handleSubmit(onSubmit)
                        }
                      >
                        <div className="form__heading">
                          <h4>Introduction</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (en)</label>
                              <Controller
                                {...register("incident_intro_en", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfig}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.incident_intro_en?.type === "required" && (
                                <span className="formError">
                                  Introduction (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (ar)</label>
                              <Controller
                                {...register("incident_intro_ar", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfigAra}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    className="arabic"
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.incident_intro_ar?.type === "required" && (
                                <span className="formError">
                                  Introduction (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* 
                        <div className="form__heading">
                          <h4>Terms and Conditions</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (en)</label>
                              <Controller
                                {...register("incident_terms_en", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfig}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.incident_terms_en?.type === "required" && (
                                <span className="formError">
                                  Terms and Conditions (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (ar)</label>
                              <Controller
                                {...register("incident_terms_ar", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfig}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.incident_terms_ar?.type === "required" && (
                                <span className="formError">
                                  Terms and Conditions (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div> */}
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          disabled={isSubmitting}
                        >
                          {agreementDetails
                            ? isSubmitting
                              ? "Updating..."
                              : "Update"
                            : isSubmitting
                              ? "Submitting..."
                              : "Submit"}
                        </button>
                        <button className="btn btn-danger" onClick={dontSubmit}>
                          Cancel
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
}

export default Introduction;
