
// local
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "https://tsd.shtdevops.xyz/ecom-backend/";
export const API_BASE_IMAGE_URL = process.env.REACT_APP_API_BASE_IMAGE_URL? process.env.REACT_APP_API_BASE_IMAGE_URL : "https://tsd.shtdevops.xyz/ecom-backend/";
// live 1
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "http://10.0.12.11:8000/";
// live 2
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL: "http://10.0.12.10:8000/";
// export const API_BASE_IMAGE_URL = process.env.REACT_APP_API_BASE_IMAGE_URL ? process.env.REACT_APP_API_BASE_IMAGE_URL : "/mnt/cms/uploads/";
export const webUrl = "http://esc.shtdevops.xyz/";
// export const API_BASE_URL = 'https://tsd.shtdevops.xyz/ecom-backend/'
export const editorConfig = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "undo",
    "ul",
    "ol",
    "indent",
    "outdent",
  ],
  placeholder: "type here",
  askBeforePasteHTML: false,
  // forcePlainText: true,
  enter: "div",
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  sanitize: function (text) {
    // Remove HTML tags from text
    return text.replace(/<[^>]*>/g, "");
  },
  // enter: "br",
};

export const editorConfigAra = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "undo",
    "ul",
    "ol",
    "indent",
    "outdent",
  ],
  direction: "rtl",
  placeholder: "type here",
  askBeforePasteHTML: false,
  // forcePlainText: true,
  enter: "div",
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  sanitize: function (text) {
    // Remove HTML tags from text
    return text.replace(/<[^>]*>/g, "");
  },
  // enter: "br",
};
