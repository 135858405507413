import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";
import {
  getMessages,
  getModules,
  getUserDetails,
  insertUsers,
  updateUsers,
} from "../../redux/actionCreator";
import { modules, userDetails, userPath } from "../../redux/commonReducer";
import { API_BASE_URL } from "../../constants/configuration";

function EditUsers() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const module_list = useSelector(modules);
  const details = useSelector(userDetails);
  const url = useSelector(userPath);

  const [data, setData] = useState({});
  const [err, setErr] = useState({});
  const [imageUpload, setImageUpload] = useState({
    featuredDisplayImage: "",
  });
  const [password, setPassword] = useState("");
  const [module, setModule] = useState([]);

  const linkArray = [
    {
      title : "Users",
      link : "/users"
    },
    {
      title : "Edit Users",
      link : null
    }
  ]

  useEffect(() => {
    dispatch(getModules());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUserDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details) {
      setData({ ...details });
      setModule(details.permissions);
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const featuredImageSelectedHandler = (e) => {
    const file = e.target.files[0];
    const fileType = file.name
      .substr(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    if (["jpeg", "jpg", "png"].includes(fileType)) {
      setData({
        ...data,
        profile_image: e.target.files[0],
      });
      setImageUpload({
        ...imageUpload,
        featuredDisplayImage: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const validate = () => {
    let temp = {};
    if (data.name?.trim()?.length == 0) {
      temp = { ...temp, name: "Full name is required" };
    }
    if (data.username?.trim()?.length == 0) {
      temp = { ...temp, username: "User name is required" };
    }
    // if (data.password?.trim()?.length == 0) {
    //   temp = { ...temp, password: "Password is required" };
    // }
    if (data.email?.trim()?.length == 0) {
      temp = { ...temp, email: "Email is required" };
    } else {
      if (typeof data.email !== "undefined") {
        let lastAtPos = data.email.lastIndexOf("@");
        let lastDotPos = data.email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            data.email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            data.email.length - lastDotPos > 2
          )
        ) {
          temp = { ...temp, email: "Please enter valid email" };
        }
      }
    }
    // if (data.phone.trim() == "") {
    //   temp = { ...temp, phone: "Please enter phone number" };
    // } else {
    //   if (data.phone.length >= 9 && data.phone.length <= 12) {
    //     let str = data.phone.replace("+", "");
    //     if (!str.match(/^[0-9]+$/)) {
    //       temp = { ...temp, phone: "Please enter valid phone number" };
    //     }
    //   } else {
    //     temp = { ...temp, phone: "Please enter valid phone number" };
    //   }
    // }
    // if (data.profile_image.length == 0) {
    //   temp = { ...temp, profile_image: "Profile image is required" };
    // }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("id", data._id);
      formData.append("name", data.name);
      formData.append("username", data.username);
      // formData.append("password", data.password);
      formData.append("password", password?.length ? password : "");
      formData.append("email", data.email);
      // formData.append("phone", data.phone);
      formData.append("user_status", data.user_status);
      // formData.append("profile_image", data.profile_image);
      module?.forEach((item, i) => {
        formData.append(`permissions[]`, item);
      });
      dispatch(
        updateUsers(formData, (res) => {
          if (res == false) {
            setIsSubmitting(false);
          }
          navigate("/users");
        })
      );
    }
  };

  const onModuleChangeHandler = (checked, option, position) => {
    let arr = [...data.permissions];
    let obj = { ...arr[position] };
    obj[option] = checked ? 1 : 0;
    arr[position] = obj;
    setData({
      ...data,
      permissions: [...arr],
    });
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
          <PageTitle title = "Edit User" linkArray = {linkArray}/>
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="full name"
                            // {...register("full_name", { required: true })}
                            value={data.name}
                            onChange={(e) => {
                              onChangeHandler("name", e.target.value);
                              setErr({ ...err, name: "" });
                            }}
                          />
                          {err.name && (
                            <span className="formError">{err.name}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Username</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="username"
                            autoFocus="off"
                            // {...register("username", { required: true })}
                            value={data.username}
                            onChange={(e) => {
                              onChangeHandler("username", e.target.value);
                              setErr({ ...err, username: "" });
                            }}
                          />
                          {err.username && (
                            <span className="formError">{err.username}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            // {...register("password", { required: true })}
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setErr({ ...err, password: "" });
                            }}
                          />
                          {err.password && (
                            <span className="formError">{err.password}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            // {...register("email", {
                            //   required: true,
                            //   pattern:
                            //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            // })}
                            value={data.email}
                            onChange={(e) => {
                              onChangeHandler("email", e.target.value);
                              setErr({ ...err, email: "" });
                            }}
                          />
                          {err.email && (
                            <span className="formError">{err.email}</span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            // {...register("phone", {
                            //   required: true,
                            //   pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                            // })}
                            value={data.phone}
                            onChange={(e) => {
                              onChangeHandler("phone", e.target.value);
                              setErr({ ...err, phone: "" });
                            }}
                          />
                          {err.phone && (
                            <span className="formError">{err.phone}</span>
                          )}
                        </div>
                      </div> */}
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label>Image</label>
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Image"
                            // {...register("image", {
                            //   required: true,
                            // })}
                            // onChange={(e) => handleImagePreview(e)}
                            onChange={(e) => {
                              featuredImageSelectedHandler(e);
                              setErr({ ...err, profile_image: "" });
                            }}
                          />
                          {err.profile_image && (
                            <span className="formError">
                              {err.profile_image}
                            </span>
                          )}
                          {((imageUpload.featuredDisplayImage &&
                            imageUpload.featuredDisplayImage.length) ||
                            (data.profile_image &&
                              data.profile_image.length)) != 0 ? (
                            <div className="preview__img">
                              <img
                                src={
                                  imageUpload.featuredDisplayImage
                                    ? imageUpload.featuredDisplayImage
                                    : `${API_BASE_URL}${url}/${data.profile_image}`
                                }
                                className="preview"
                                alt="Preview"
                              />
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Status</label>
                          <br />
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="1"
                              // {...register("status", { required: true })}
                              checked={data.user_status == "1"}
                              onChange={(e) =>
                                onChangeHandler("user_status", e.target.value)
                              }
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Active
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="0"
                              // {...register("status", { required: true })}
                              checked={data.user_status == "0"}
                              onChange={(e) =>
                                onChangeHandler("user_status", e.target.value)
                              }
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              Inactive
                            </label>
                          </div>
                          <br />
                          {err.status && (
                            <span className="formError">{err.status}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          {module_list?.map((ele, i) => {
                            return (
                              <div className="col-md-3">
                                <div className="form-group">
                                  <div className="form-check custom-checkbox mb-3 checkbox-primary">
                                    <input
                                      type="checkbox"
                                      checked={module?.includes(ele._id) ? true : false}
                                      className="form-check-input"
                                      id={i}
                                      name={ele.module_slug}
                                      value={ele._id}
                                      onClick={(e) =>
                                        e.target.checked
                                          ? setModule([...module, ele._id])
                                          : setModule(
                                              module.filter((item) => item != ele._id)
                                            )
                                      }
                                    />
                                    <label className="form-check-label" for={i}>
                                      {ele.module_name}
                                    </label>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mr-1"
                      onClick={() => onSubmitHandler()}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Updating..." : "Update"}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => navigate("/users")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default EditUsers;
