import React from 'react'

function Footer() {
    let date =  new Date().getFullYear();
    return (
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            {date} © Dubai Economic Security.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-right d-none d-sm-block">
                            {date} © SmartHatch
                            </div>
                        </div>
                    </div>
                </div>
                
            </footer>
        </>
    )
}

export default Footer