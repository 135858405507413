import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { API_BASE_IMAGE_URL } from "../../constants/configuration";
import { getBannerdetails, updateBanners } from "../../redux/actionCreator";
import { banner_details, banner_path } from "../../redux/commonReducer";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";

function EditVideoBanner() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bannerDetail = useSelector(banner_details);
  const bannerPath = useSelector(banner_path);
  const [titleen, setTitleen] = useState("");
  const [titlear, setTitlear] = useState("");
  const [desc_ar, setDesc_ar] = useState("");
  const [desc_en, setDesc_en] = useState("");
  const [urlen, setUrlen] = useState("");
  const [urlar, setUrlar] = useState("");
  const [status, setStatus] = useState("");
  const [thumbImage, setThumbImage] = useState(null);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({})

  const linkArray = [
    {
      title: "Banners",
      link: "/banners",
    },
    {
      title: "Edit Banners",
      link: null,
    },
  ];

  useEffect(() => {
    if (bannerDetail) {
      setTitleen(bannerDetail?.title_en);
      setTitlear(bannerDetail?.title_ar);
      setDesc_en(bannerDetail?.desc)
      setDesc_ar(bannerDetail?.desc_ar)
      setUrlen(bannerDetail?.video_url);
      setUrlar(bannerDetail?.video_url_ar);
      setStatus(bannerDetail?.active);
    }
  }, [bannerDetail]);

  useEffect(() => {
    if (id) {
      dispatch(getBannerdetails(id));
    }
  }, [id]);

  const handleFileChange = (e) => {
    handleImagePreview(e);
    if (e.target.name === "thumb_image") {
      setThumbImage(e.target.files[0]);
    } else {
      setImage(e.target.files[0])
    }
  };

  const validate = () => {
    let flag = true
    let err = {...errors}
    if (!titleen) {
      err = ({ ...err, titleen: "required" })
      flag = false
    }
    if (!titlear) {
      err = ({ ...err, titlear: "required" })
      flag = false
    }
    if (!desc_en) {
      err = ({ ...err, desc_en: "required" })
      flag = false
    }
    if (!desc_ar) {
      err = ({ ...err, desc_ar: "required" })
      flag = false
    }
    if (desc_en?.length > 400) {
      err = ({ ...err, desc_en: "maxLength" })
      flag = false
    }
    if (desc_ar?.length > 400) {
      err = ({ ...err, desc_ar: "maxLength" })
      flag = false
    }
    if (!urlen) {
      err = ({ ...err, urlen: "required" })
      flag = false
    }
    setErrors(err)
    return flag
  }

  const handleUpdate = (e) => {
    if (!(validate())) {
      return
    }
    setIsSubmitting(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("title_en", titleen);
    formData.append("title_ar", titlear);
    formData.append("image_name", image);
    formData.append("thumb_image", thumbImage);
    formData.append("desc", desc_en);
    formData.append("desc_ar", desc_ar);
    formData.append("video_url", urlen);
    formData.append("video_url_ar", urlar);
    formData.append("active", status);
    formData.append("id", bannerDetail?._id);
    dispatch(
      updateBanners(formData, (res) => {
        setIsSubmitting(false);
        if (res === true) {
          setIsSubmitting(false);
          navigate("/banners");
          toast.success("Banner Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          e.target.reset();
          navigate("/banners");
        }
      })
    );
  };

  console.log({errors})

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Edit Video Banners" linkArray={linkArray} />
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="col-md-12 pl-0">
                          <span className="resolution">Thumbnail Image (image size : 800x400)</span>
                          {imagePreview.thumb_image ? (
                            <div className="preview__img mt-2">
                              <img
                                src={imagePreview.thumb_image}
                                className="preview"
                                alt="Preview"
                              />
                            </div>
                          ) : (
                            <div className="preview__img mt-2">
                              <img
                                src={
                                  API_BASE_IMAGE_URL +
                                  bannerPath +
                                  "/" +
                                  bannerDetail?.thumb_image
                                }
                                className="preview"
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 pl-0">
                          <span className="resolution">Background Image (image size : 2160x1200)</span>
                          {imagePreview.image_name ? (
                            <div className="preview__img mt-2">
                              <img
                                src={imagePreview.image_name}
                                className="preview"
                                alt="Preview"
                              />
                            </div>
                          ) : (
                            <div className="preview__img mt-2">
                              <img
                                src={
                                  API_BASE_IMAGE_URL +
                                  bannerPath +
                                  "/" +
                                  bannerDetail?.image_name
                                }
                                className="preview"
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Thumbnail Image</label>
                          <input
                            type="file"
                            className="form-control"
                            name="thumb_image"
                            onChange={handleFileChange}
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Background Image</label>
                          <input
                            type="file"
                            className="form-control"
                            name="image_name"
                            onChange={handleFileChange}
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Title (En) <span className="required">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            value={titleen}
                            onChange={(e) => {setTitleen(e.target.value); setErrors({...errors, titleen: ""})}}
                            placeholder="type here"
                          />
                          {errors.titleen === "required" && (
                            <span className="formError">
                              Title (En) is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Title (Ar)<span className="required">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            value={titlear}
                            onChange={(e) => {setTitlear(e.target.value); setErrors({...errors, titlear: ""})}}
                            placeholder="type here"
                          />
                          {errors.titlear === "required" && (
                            <span className="formError">
                              Title (Ar) is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Description (En) (400 characters maximum)<span className="required">*</span></label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={desc_en}
                            onChange={(e) => {setDesc_en(e.target.value); setErrors({...errors, desc_en: ""})}}
                            placeholder="type here"
                          />
                          {errors.desc_en === "required" && (
                            <span className="formError">
                              Description (En) is required
                            </span>
                          )}
                          {errors.desc_en === "maxLength" && (
                            <span className="formError">
                              Description (En) exceeded 400 characters
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Description (Ar) (400 characters maximum)<span className="required">*</span></label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={desc_ar}
                            onChange={(e) => {setDesc_ar(e.target.value); setErrors({...errors, desc_ar: ""})}}
                            placeholder="type here"
                          />
                           {errors.desc_ar === "required" && (
                            <span className="formError">
                              Description (Ar) is required
                            </span>
                          )}
                          {errors.desc_ar === "maxLength" && (
                            <span className="formError">
                              Description (Ar) exceeded 400 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>URL (En)<span className="required">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            value={urlen}
                            onChange={(e) => {setUrlen(e.target.value);setErrors({...errors, urlen: ""})}}
                            placeholder="type here"
                          />
                           {errors.urlen === "required" && (
                            <span className="formError">
                              URL (En) is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>URL (Ar)</label>
                          <input
                            type="text"
                            className="form-control"
                            value={urlar}
                            onChange={(e) => setUrlar(e.target.value)}
                            placeholder="type here"
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Status</label>
                          <br />
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              checked={status == "1"}
                              value={1}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Active
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              checked={status == "0"}
                              value={0}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mr-1"
                      onClick={(e) => handleUpdate(e)}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Updating..." : "Update"}
                    </button>
                    <Link to="/banners">
                      <button type="submit" className="btn btn-danger">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default EditVideoBanner;
