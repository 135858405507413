import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';
import { updateRedux } from './commonReducer';
import { useState } from 'react';
// import { data } from 'jquery';

const formHeader = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem("token")}`
  },
}

export const login = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/login`, data).then(res => {
    if (res.data.status == true) {
      localStorage.setItem('username', res.data.username)
      localStorage.setItem('role', res.data.user_type)
      // localStorage.setItem("permissions", JSON.stringify(res.data.permissions));
      dispatch(getUserPermissions());
      // dispatch(updateRedux({ key: "permissions", value: res.data.permissions }))
      localStorage.setItem('token', res.data.accessToken)
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error("Please check your username or password", {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertBanners = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertBanner`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getBanners = (formData) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getBanner`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "bannersLists", value: res.data.banner }))
      dispatch(updateRedux({ key: "banner_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "banner_path", value: res.data.path }))
      dispatch(updateRedux({ key: "banner_page_status", value: res.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getBannerdetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getBannerDetails/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "banner_details", value: res.data.banner }))
      dispatch(updateRedux({ key: "banner_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}
export const deleteBanner = (id, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/deleteBanner`, id).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}



export const updateBanners = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateBanner`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateBannerstatus = (id, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/updateBannerStatus/${id}`).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}



export const insertHomeBanners = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertHome`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getHomeBannerdetail = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getHome`).then((res) => {
    if (res.data.status) {
      callback(res.data.home)
      dispatch(updateRedux({ key: "homebanner_details", value: res.data.home }))
      dispatch(updateRedux({ key: "homebanner_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateHomeBanners = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateHome`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getBannertype = (callback) => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}admin/get-banner-type`).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const changeBannertype = (callback) => (dispatch) => {
  axiosFormInstance.put(`${API_BASE_URL}admin/change-banner-type`).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

// ABOUT

export const insertAbout = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertAbout`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAbout = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getAbout`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "about_details", value: res.data.about }))
      dispatch(updateRedux({ key: "about_path", value: res.data.path }))
      callback(res.data.about)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateAbout = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateAbout`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const insertValues = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertAboutValues`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAboutValues = (formData, id) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getAboutValues`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "AboutValues", value: res.data.about }))
      dispatch(updateRedux({ key: "AboutValues_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "AboutValues_status", value: res.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateAboutValuesStatus = (id, callback) => () => {
  axiosInstance.get(`${API_BASE_URL}admin/updateAboutValuesStatus/${id}`).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const deleteAboutValues = (id, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/deleteAboutValues`, id).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAboutValuesDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getAboutValuesDetails/${id}`).then((res) => {
    if (res.data.status) {
      callback(res.data.aboutValues)
      dispatch(updateRedux({ key: "aboutValues_details", value: res.data.aboutValues }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateAboutValues = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateAboutValues`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getValues = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getHome`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homebanner_details", value: res.data.home }))
      dispatch(updateRedux({ key: "homebanner_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}


// Roles and assignments

export const insertRoles = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertRolesAssign`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getRolesAssign = (formData, id) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getRolesAssign`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "roles_list", value: res.data.rolesAssign }))
      dispatch(updateRedux({ key: "roleslist_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "roleslist_status", value: res.data.page_status }))
      dispatch(updateRedux({ key: "roles_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const deleteRolesAssign = (id, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/deleteRolesAssign`, id).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getRolesAssignDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getRolesAssignDetails/${id}`).then((res) => {
    if (res.data.status) {
      callback(res.data.rolesAssign)
      dispatch(updateRedux({ key: "roles_details", value: res.data.rolesAssign }))
      dispatch(updateRedux({ key: "roles_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateRolesAssign = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateRolesAssign`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const updateRolesAssignStatus = (id, callback) => () => {
  axiosInstance.post(`${API_BASE_URL}admin/updateRolesAssignStatus/${id}`).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}


// jurisdictions

export const insertJurisdictionAuthorities = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertJurisdictionAuthorities`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getJurisdictionAuthorities = (formData, id) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getJurisdictionAuthorities`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "jurisdiction_list", value: res.data.jurisdictionAuthorities }))
      dispatch(updateRedux({ key: "jurisdictionList_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "jurisdictionList_status", value: res.data.page_status }))
      dispatch(updateRedux({ key: "jurisdiction_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const deleteJurisdictionAuthorities = (id, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/deleteJurisdictionAuthorities`, id).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getJurisdictionAuthoritiesDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getJurisdictionAuthoritiesDetails/${id}`).then((res) => {
    if (res.data.status) {
      callback(res.data.jurisdictionAuthorities)
      dispatch(updateRedux({ key: "jurisdiction_details", value: res.data.jurisdictionAuthorities }))
      dispatch(updateRedux({ key: "jurisdiction_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}



export const updateJurisdictionAuthoritiesStatus = (id, callback) => () => {
  axiosInstance.post(`${API_BASE_URL}admin/updateJurisdictionAuthoritiesStatus/${id}`).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}


export const updateJurisdictionAuthorities = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateJurisdictionAuthorities`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

// Contact information

export const insertContactInfo = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertContactInfo`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getContactInfo = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getContactInfo`).then((res) => {
    if (res.data.status) {
      callback(res.data.contactInfo)
      dispatch(updateRedux({ key: "getContactInfo_detail", value: res.data.contactInfo }))
      dispatch(updateRedux({ key: "getContactInfo_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}


export const updateContactInfo = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateContactInfo`, formData).then((res) => {
    if (res.data.status) {
      callback(res.data.status)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

// insertVisionMission

export const insertVisionMission = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertVisionMission`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getVisionMission = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getVisionMission`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "visionMission_details", value: res.data.visionMission }))
      callback(res.data.visionMission)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateVisionMission = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateVisionMission`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

// MESSAGES

export const getMessages = (formData, id) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getMessages`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "messageLists", value: res.data.messages }))
      dispatch(updateRedux({ key: "messageLists_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "messageLists__status", value: res.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getModules = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getModule`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "modules", value: res.data.module_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

// DASHBOARD

export const getDashboard = () => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getDashboard`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: "dashboardItems", value: res.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertUsers = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertUser`, formData).then(res => {
    if (res.data.status == true) {
      // const formData = new FormData();
      // formData.append("sort_key", "name");
      // formData.append("sort_order", 1);
      // formData.append("page", 0);
      // dispatch(getUser(formData));
      callback(res.data.status)
    } else {
      callback(res.data.status)
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      }
      )

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUser = (formData) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getUser`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "userList", value: res.data.users_list }))
      dispatch(updateRedux({ key: "userPath", value: res.data.path }))
      dispatch(updateRedux({ key: "userCount", value: res.data.pages }))
      dispatch(updateRedux({ key: "userPageStatus", value: res.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getUserDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getUserDetail/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "userDetails", value: res.data.user_details }))
      dispatch(updateRedux({ key: "userPath", value: "user" }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateUserStatus = (id, callback) => () => {
  axiosInstance.get(`${API_BASE_URL}admin/updateUserStatus/${id}`).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const deleteUser = (id, callback) => () => {
  let data = {
    id: id
  }
  axiosInstance.post(`${API_BASE_URL}admin/deleteUser`, data).then((res) => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateUsers = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateUser`, formData).then(res => {
    if (res.data.status == true) {
      // const formData = new FormData();
      // formData.append("sort_key", "name");
      // formData.append("sort_order", 1);
      // formData.append("page", 0);
      // dispatch(getUser(formData));
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })

    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getUserPermissions = () => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}admin/getUserPermissions`).then((res) => {
    if (res.data.status) {
      localStorage.setItem("permissions", res.data.permissions);
      dispatch(updateRedux({ key: "permissions", value: res.data.permissions }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getAgreement = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getIncidentContentDetails`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "agreement_details", value: res.data.incidentContent }))
      callback(res.data.incidentContent)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateAgreement = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateIncidentContent`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertAgreement = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertIncidentContent`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}


// INCIDNT TOGGLE


export const ToggleStatus = (callback) => () => {
  axiosFormInstance.get(`${API_BASE_URL}admin/check-report-button`).then(res => {
    // if (res.data.status == true) {
    //   if (callback) {
    //     callback(res.data.status)
    //   }
    // } else {
    //   toast.error(res.data.message, {
    //     position: "bottom-center",
    //     autoClose: 3000
    //   })
    //   if (callback) {
    //     callback(res.data.status)
    //   }
    // }
    // console.log(res)
    if (res.data.status) {
      callback && callback(res.data.data.status)
    } else {
      callback && callback(res.data.data.status)
    }
  }).catch((err) => {
    console.log(err)
  })
}



export const ToggleEnableStatus = (callback) => () => {
  axiosFormInstance.get(`${API_BASE_URL}admin/enable-report-button`).then(res => {
    if (res.data.status) {
      callback && callback(res.data.data.status)
    } else {
      callback && callback(res.data.data.status)
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const ToggleDisableStatus = (callback) => () => {
  axiosFormInstance.get(`${API_BASE_URL}admin/disable-report-button`).then(res => {
    if (res.data.status) {
      callback && callback(res.data.data.status)
    } else {
      callback && callback(res.data.data.status)
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const addNews = (data, callback) => () => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertNews`, data).then(res => {
    if (res.data.status) {
      callback && callback(res.data.status)
    } else {
      callback && callback(res.data.status)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateNews = (data, callback) => () => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateNews`, data).then(res => {
    if (res.data.status) {
      callback && callback(res.data.status)
    } else {
      callback && callback(res.data.status)
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getNews = (data, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getNews`, data).then(res => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "newsList", value: res.data.news }))
      dispatch(updateRedux({ key: "newsPages_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "newsPages_status", value: res.data.page_status }))
      // dispatch(updateRedux({ key: "banner_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getNewsDetail = (id, callback) => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}admin/getNewsDetails/${id}`).then(res => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "newsDetail", value: res.data.news }))
      callback && callback(res.data.news)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteNews = (id, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/deleteNews`, { id }).then(res => {
    if (res.data.status) {
      callback && callback(res.data.status)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertTagline = (data, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertTagline`, data).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      callback && callback(res.data.status)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      callback && callback(res.data.status)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getTagline = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getTagline`).then(res => {
    if (res.data.status) {
      callback && callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateTagline = (data, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateTagline`, data).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      callback && callback(res.data.status)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      callback && callback(res.data.status)
    }
  }).catch((err) => {
    console.log(err)
  })
}


// PRIVACY 
export const insertPrivacy = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertPrivacy`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updatePrivacy = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updatePrivacy`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getPrivacy = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/privacy-policy`).then((res) => {
    if (res.data.status) {
      callback(res.data?.privacy)
      dispatch(updateRedux({ key: "getPrivacy_detail", value: res.data.privacy }))
      dispatch(updateRedux({ key: "getPrivacy_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

// TERMS 
export const insertTerms = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertTerms`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateTerms = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateTerms`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getTerms = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/terms`).then((res) => {
    if (res.data.status) {
      callback(res.data?.terms)
      dispatch(updateRedux({ key: "getTerms_detail", value: res.data.terms }))
      dispatch(updateRedux({ key: "getTerms_path", value: res.data.path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}



export const fileUploader = (formdata, cb) => {
  axiosFormInstance.post(API_BASE_URL + 'admin/uploadGallery', formdata).then(res => {
    cb && cb(res.data)
    if (res.data.status) {
      // toast.success(res.data.message)
    } else toast.error(res.data.message)

  })
}
export const uploadVideo = (formdata, cb) => {
  axiosFormInstance.post(API_BASE_URL + 'admin/uploadVideo', formdata).then(res => {
    cb && cb(res.data)
    if (res.data.status) {
      // toast.success(res.data.message)
    } else toast.error(res.data.message)

  })
}
export const deletePreview = (data, cb) => {
  axiosInstance.post(API_BASE_URL + 'admin/deletePreviewGallery', data).then(res => {
    cb && cb(res.data)
    if (res.data.status) {
      // toast.success(res.data.message)
    } else toast.error(res.data.message)

  })
}