import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { API_BASE_IMAGE_URL, webUrl } from "../../constants/configuration";
import {
  changeBannertype,
  deleteBanner,
  getBanners,
  getBannertype,
  getUserPermissions,
  updateBannerstatus,
} from "../../redux/actionCreator";
import {
  bannersLists,
  banner_count,
  permissions,
  banner_path,
  banner_page_status,
} from "../../redux/commonReducer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import { Spinner } from "react-bootstrap";

const Banner = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [show, setShow] = useState(false);
  const [statusShow, setStatusShow] = useState(false);
  const [deleteid, setDeleteID] = useState("");
  const [statusid, setStatusID] = useState("");
  const [page, setPage] = useState(0);
  const [bannerType, setBannerType] = useState("")

  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(false);

  const pageCount = useSelector(banner_count);
  const pageStatus = useSelector(banner_page_status);

  const linkArray = [
    {
      title: "Banners",
      link: null,
    },
  ];

  const handleShow = (ele) => {
    setShow(true);
    setDeleteID(ele._id);
  };

  const closeBtn = () => setShow(false);
  const handleClose = () => {
    const id = {
      id: deleteid,
    };
    dispatch(
      deleteBanner(id, (res) => {
        if (res === true) {
          dispatch(getBanners());
          toast.success("Deleted successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
    setShow(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bannerItems = useSelector(bannersLists);
  const bannerPath = useSelector(banner_path);
  useEffect(() => {
    dispatch(getBanners());
    dispatch(getBannertype(res => {
      setBannerType(res)
    }))
  }, []);

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);

  const editHandler = (ele) => {
    ele.banner_type === "video" ? navigate(`/edit-video-banners/${ele._id}`) : navigate(`/edit-banners/${ele._id}`);
  };

  const handleStatusChange = (ele) => {
    setStatusShow(true);
    setStatusID(ele._id);
  };

  const handleStatus = () => {
    dispatch(
      updateBannerstatus(statusid, (res) => {
        if (res === true) {
          setStatusShow(false);
          dispatch(getBanners());
          toast.success("Status updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("page", e.selected);
      dispatch(getBanners(formData));
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("keyword", data?.keyword);
    formData.append("status", data?.status);
    dispatch(getBanners(formData));
  };

  const onSortHandler = (type) => {
    setSortOrder(!sortOrder);
    setSortColumn(type);
    const formData = new FormData();
    // formData.append("user_status", status);
    formData.append("page", page);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortOrder ? 1 : -1);
    dispatch(getBanners(formData));
  };

  const toggleBannerType = val => {
    dispatch(changeBannertype(res => {
      toast.success("Banner Type changed successfully", {
        position: "bottom-center",
        autoClose: 3000
      })
      setBannerType(res.data.data)
      dispatch(getBanners());
      setSortColumn("createdAt")
      setSortOrder(false)
    }))
    // setBannerType(val ? "video" : "image")
  }

  const showAddButton = () => {
    if (bannerType === "image") {
      return true
    } else {
      if (bannerItems && bannerItems?.length < 1) {
        return true
      }
    }
    return false
  }

  return (
    <>
      {/* DELETE */}
      <Modal show={show} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to delete?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={closeBtn}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleClose}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* STATUS UPDATE */}
      <Modal show={statusShow} centered className="deleteModal">
        <Modal.Body>
          <h2>Do you want to update status?</h2>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => setStatusShow(false)}
          >
            No
          </button>
          <button className="btn btn-primary" onClick={handleStatus}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Banners" linkArray={linkArray} />
            {bannerType ? <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="default__heading">
                    {bannerType === "image" ? <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="forms">
                        <div className="form-group">
                          <select
                            {...register("status")}
                            className="form-control form-select"
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                        <div className="search">
                          <div className="input-group">
                            <input
                              type="text"
                              {...register("keyword")}
                              className="form-control"
                              placeholder="Search here"
                            />
                            <button className="btn btn-primary" type="submit">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </form> : <div></div>}
                    <div className="button d-flex">
                      <div className="avtiveIncident">
                        <p className={bannerType === "image" ? "selected-type" : ""}>Image</p>
                        <label className="incident-switch banner-switch" onChange={(e) => toggleBannerType(e.target.checked)} style={{ width: 30 }}>
                          <input type="checkbox" style={{ width: 30 }} checked={bannerType === "video"} />
                          <span className="switch" style={{ width: 30, height: 20 }} />
                        </label>
                        <p className={bannerType === "video" ? "selected-type" : ""}>Video</p>
                      </div>
                      {showAddButton() ? <Link to={bannerType === "image" ? "/add-banners" : "/add-video-banners"}>
                        <button className="btn btn-primary">Add Banner</button>
                      </Link> : null}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div id="banners_data" className="dataTables_wrapper">
                      {!pageCount == 0 ? (
                        <table
                          className="display w-100 dataTable table table-striped"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting_asc">Image</th>
                              <th
                                onClick={() => onSortHandler("title_en")}
                                className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''} 
                              ${sortColumn == "title_en" && sortOrder == true
                                    ? "active"
                                    : sortColumn == "title_en" &&
                                      sortOrder == false
                                      ? "inactive"
                                      : ""
                                  }`}
                              >
                                Title (En)
                              </th>
                              <th
                                onClick={() => onSortHandler("title_ar")}
                                className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''}
                              ${sortColumn == "title_ar" && sortOrder == true
                                    ? "active"
                                    : sortColumn == "title_ar" &&
                                      sortOrder == false
                                      ? "inactive"
                                      : ""
                                  }`}
                              >
                                Title (Ar)
                              </th>
                              <th
                                onClick={() => onSortHandler("url_en")}
                                className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''}
                              ${sortColumn == "url_en" && sortOrder == true
                                    ? "active"
                                    : sortColumn == "url_en" && sortOrder == false
                                      ? "inactive"
                                      : ""
                                  }`}
                              >
                                Url (En)
                              </th>
                              <th
                                onClick={() => onSortHandler("url_ar")}
                                className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''} 
                              ${sortColumn == "url_ar" && sortOrder == true
                                    ? "active"
                                    : sortColumn == "url_ar" && sortOrder == false
                                      ? "inactive"
                                      : ""
                                  }`}
                              >
                                Url (Ar)
                              </th>
                              {bannerType === "image" ? <th
                                onClick={() => onSortHandler("active")}
                                className={`sorting ${bannerType === "image" ? 'cursor-pointer' : ''} 
                              ${sortColumn == "active" && sortOrder == true
                                    ? "active"
                                    : sortColumn == "active" && sortOrder == false
                                      ? "inactive"
                                      : ""
                                  }`}
                              >
                                Status
                              </th> : null}
                              <th className="sorting">Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {bannerItems?.map((ele, i) => {
                              return (
                                <tr className="odd" role="row">
                                  <td>
                                    <img
                                      className="td-img"
                                      src={
                                        API_BASE_IMAGE_URL +
                                        bannerPath +
                                        "/" +
                                        ele.image_name
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <td>{ele.title_en}</td>
                                  <td>{ele.title_ar}</td>
                                  <td><a href={ele.banner_type === "video" && ele.video_url} target="_blank">{ele.banner_type === "video" ? ele.video_url : ele.url_en}</a></td>
                                  <td><a href={ele.banner_type === "video" && ele.video_url_ar} target="_blank">{ele.banner_type === "video" ? ele.video_url_ar : ele.url_ar}</a></td>
                                  {bannerType === "image" ? <td>
                                    {ele.active === 1 ? (
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() => handleStatusChange(ele)}
                                      >
                                        Active
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => handleStatusChange(ele)}
                                      >
                                        Inactive
                                      </button>
                                    )}
                                  </td> : null}

                                  <td>
                                    <button
                                      className="btn btn-sm btn-table"
                                      onClick={() => editHandler(ele)}
                                    >
                                      {" "}
                                      <img
                                        src="./assets/img/table-edit.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                    {/* {bannerType === "image" ? <button */}
                                    {true ? <button
                                      className="btn btn-sm btn-table"
                                      onClick={() => handleShow(ele)}
                                    >
                                      <img
                                        src="./assets/img/table-delete.png"
                                        alt=""
                                      />
                                    </button> : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="noData-found">
                          <h3>No Data Listed!</h3>
                        </div>
                      )}

                      <div className="paginationStyle">
                        <p>{pageStatus}</p>
                        {pageCount > 1 ? (
                          <ReactPaginate
                            previousLabel={<>❮</>}
                            nextLabel={<>❯</>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            // breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            initialPage={page}
                            onPageChange={(e) => handlePageChange(e)}
                            containerClassName="pagination"
                            activeClassName="pageActive"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> :
              <div className="d-flex w-100 align-items-center justify-content-center h-100" style={{minHeight: 500}}>
                <Spinner style={{color: "#0a0f31"}}></Spinner>
              </div>
            }
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Banner;
