import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import JoditReact from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getContactInfo,
  insertContactInfo,
  updateContactInfo,
} from "../../redux/actionCreator";
import { Controller, useForm } from "react-hook-form";
import { API_BASE_IMAGE_URL, editorConfig, editorConfigAra } from "../../constants/configuration";
import { useEffect } from "react";
import { getContactInfo_detail, getContactInfo_path } from "../../redux/commonReducer";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";

function Info() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const infoDetail = useSelector(getContactInfo_detail);
  const getContactInfoPath = useSelector(getContactInfo_path);

  const linkArray = [
    {
      title: "Contact Info",
      link: null,
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("address_en", data?.address_en);
    formData.append("address_ar", data?.address_ar);
    formData.append("telephone", data?.telephone);
    formData.append("email", data?.email);
    formData.append("banner_name", data?.banner[0]);
    dispatch(
      insertContactInfo(formData, (res) => {
        if (res === true) {
          dispatch(
            getContactInfo((data) => {
              reset({
                address_en: data?.address_en,
                address_ar: data?.address_ar,
                telephone: data?.telephone,
                email: data?.email,
              });
            })
          );
          setIsSubmitting(false);
          toast.success("Contact info added successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getContactInfo((data) => {
        reset({
          address_en: data?.address_en,
          address_ar: data?.address_ar,
          telephone: data?.telephone,
          email: data?.email,
        });
      })
    );
  }, []);

  const handleUpdate = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("address_en", data?.address_en);
    formData.append("address_ar", data?.address_ar);
    formData.append("telephone", data?.telephone);
    formData.append("email", data?.email);
    formData.append("banner_name", data?.banner[0]);
    formData.append("id", infoDetail._id);
    dispatch(
      updateContactInfo(formData, (res) => {
        dispatch(
          getContactInfo((data) => {
            reset({
              address_en: data?.address_en,
              address_ar: data?.address_ar,
              telephone: data?.telephone,
              email: data?.email,
            });
          })
        );
        if (res === true) {
          setIsSubmitting(false);
          toast.success("Contact info Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Contact Info" linkArray={linkArray} />
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form
                      onSubmit={
                        infoDetail
                          ? handleSubmit(handleUpdate)
                          : handleSubmit(onSubmit)
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Address (en)</label>
                            <Controller
                              control={control}
                              defaultValue={""}
                              {...register("address_en", { required: true })}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfig}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                />
                              )}
                            />
                            {errors.address_en?.type === "required" && (
                              <span className="formError">
                                Address (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Address (ar)</label>
                            <Controller
                              {...register("address_ar", { required: true })}
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfigAra}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                  className="arabic"
                                />
                              )}
                            />
                            {errors.address_ar?.type === "required" && (
                              <span className="formError">
                                Address (ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Telephone</label>
                            <input
                              type="text"
                              {...register("telephone", {
                                required: true,
                                min: 100000000,
                                max: 99999999999,
                                pattern: /^[0-9\s]+$/,
                              })}
                              className="form-control"
                              placeholder="Telephone"
                            />
                            {errors.telephone?.type === "required" && (
                              <span className="formError">
                                Telephone is required
                              </span>
                            )}
                            {errors.telephone?.type === "pattern" && (
                              <span className="formError">
                                Please type valid number
                              </span>
                            )}
                            {errors.telephone?.type === "min" && (
                              <span className="formError">
                                Number must have at least 9 digits
                              </span>
                            )}
                            {errors.telephone?.type === "max" && (
                              <span className="formError">
                                Number must have at most 11 digits
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="text"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              })}
                              className="form-control"
                              placeholder="Email"
                            />
                            {errors.email?.type === "required" && (
                              <span className="formError">
                                Email is required
                              </span>
                            )}
                            {errors.email?.type === "pattern" && (
                              <span className="formError">
                                Please type valid email
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Banner <span className="resolution">(image size : 1110x330)</span></label>
                            <input
                              type="file"
                              {...register("banner", {
                                required: infoDetail ? false : true,
                              })}
                              className="form-control"
                              placeholder="file"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.banner
                              ? errors.banner?.type === "required" && (
                                  <span className="formError">
                                    Banner Image is required
                                  </span>
                                )
                              : ""}
                          </div>
                          {infoDetail ? (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12 pl-0">
                                  {imagePreview.banner ? (
                                    <div className="preview__img">
                                      <img
                                        src={imagePreview.banner}
                                        className="preview"
                                        alt="Preview"
                                      />
                                    </div>
                                  ) : (
                                    <div className="preview__img">
                                      <img
                                        src={
                                          API_BASE_IMAGE_URL +
                                          getContactInfoPath +
                                          "/" +
                                          infoDetail?.banner_name
                                        }
                                        className="preview"
                                        alt=""
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {imagePreview.banner && (
                                <div className="preview__img">
                                  <img
                                    src={imagePreview.banner}
                                    className="preview"
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        disabled={isSubmitting}
                      >
                        {infoDetail
                          ? isSubmitting
                            ? "Updating..."
                            : "Update"
                          : isSubmitting
                          ? "Submitting..."
                          : "Submit"}
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={(e) => navigate('/')}
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Info;
