import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { getDashboard, getUserPermissions } from "../../redux/actionCreator";
import { dashboardItems } from "../../redux/commonReducer";
import { modulePermission } from "../../utils/helpers";

function Home() {
  const dispatch = useDispatch();
  const dashboardLists = useSelector(dashboardItems);
  useEffect(() => {
    dispatch(getDashboard());
    dispatch(getUserPermissions());
  }, []);
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle />
              <div className="row dashboard__items">
                {modulePermission()?.includes("home") ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <span className="badge badge-soft-primary float-right">
                            Banners
                          </span>
                          <h5 className="card-title mb-0"> Home - Banners</h5>
                        </div>
                        <div className="row d-flex align-items-center mb-4">
                          <div className="col-md-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {dashboardLists?.totalBannerCount}
                            </h2>
                          </div>
                          <div className="col-md-5 text-right">
                            <span className="text-muted">
                              Active : {dashboardLists?.activeBannerCount}
                            </span>{" "}
                            <br />
                            <span className="text-muted">
                              Inactive : {dashboardLists?.inactiveBannerCount}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress shadow-sm"
                          style={{ height: 5 }}
                        >
                          <div
                            className="progress-bar bg-secondary w-100"
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                      {/*end card body*/}
                    </div>
                    {/* end card*/}
                  </div>
                ) : null}{" "}
                {/* end col*/}
                {modulePermission()?.includes("about") ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <span className="badge badge-soft-primary float-right">
                            Our Values
                          </span>
                          <h5 className="card-title mb-0">Our Values</h5>
                        </div>
                        <div className="row d-flex align-items-center mb-4">
                          <div className="col-md-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {dashboardLists?.totalAboutValuesCount}
                            </h2>
                          </div>
                          <div className="col-md-5 text-right">
                            <span className="text-muted">
                              Active : {dashboardLists?.activeAboutValuesCount}
                            </span>{" "}
                            <br />
                            <span className="text-muted">
                              Inactive :{" "}
                              {dashboardLists?.inactiveAboutValuesCount}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress shadow-sm"
                          style={{ height: 5 }}
                        >
                          <div
                            className="progress-bar bg-secondary w-100"
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                      {/*end card body*/}
                    </div>
                    {/* end card*/}
                  </div>
                ) : null}
                {/* end col*/}
                {modulePermission()?.includes("roles_assignment") ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <span className="badge badge-soft-primary float-right">
                            Roles & Assignment
                          </span>
                          <h5 className="card-title mb-0">
                            Roles & Assignment
                          </h5>
                        </div>
                        <div className="row d-flex align-items-center mb-4">
                          <div className="col-md-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {dashboardLists?.totalRolesAssignCount}
                            </h2>
                          </div>
                          <div className="col-md-5 text-right">
                            <span className="text-muted">
                              Active : {dashboardLists?.activeRolesAssignCount}
                            </span>{" "}
                            <br />
                            <span className="text-muted">
                              Inactive :{" "}
                              {dashboardLists?.inactiveRolesAssignCount}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress shadow-sm"
                          style={{ height: 5 }}
                        >
                          <div
                            className="progress-bar bg-secondary w-100"
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                      {/*end card body*/}
                    </div>
                    {/*end card*/}
                  </div>
                ) : null}{" "}
                {/* end col*/}
                {modulePermission()?.includes("jurisdictions_authorities") ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <span className="badge badge-soft-primary float-right">
                            Jurisduction & Authorities
                          </span>
                          <h5 className="card-title mb-0">
                            Jurisduction & Authorities
                          </h5>
                        </div>
                        <div className="row d-flex align-items-center mb-4">
                          <div className="col-md-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {
                                dashboardLists?.totalJurisdictionAuthoritiesCount
                              }
                            </h2>
                          </div>
                          <div className="col-md-5 text-right">
                            <span className="text-muted">
                              Active :{" "}
                              {
                                dashboardLists?.activeJurisdictionAuthoritiesCount
                              }
                            </span>{" "}
                            <br />
                            <span className="text-muted">
                              Inactive :{" "}
                              {
                                dashboardLists?.inactiveJurisdictionAuthoritiesCount
                              }
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress shadow-sm"
                          style={{ height: 5 }}
                        >
                          <div
                            className="progress-bar bg-secondary w-100"
                            role="progressbar"
                          />
                        </div>
                      </div>
                      {/*end card body*/}
                    </div>
                    {/* end card*/}
                  </div>
                ) : null}
                {/* end col*/}
                {modulePermission()?.includes("users") ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <span className="badge badge-soft-primary float-right">
                            Users
                          </span>
                          <h5 className="card-title mb-0">Users</h5>
                        </div>
                        <div className="row d-flex align-items-center mb-4">
                          <div className="col-md-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {dashboardLists?.totalUserCount}
                            </h2>
                          </div>
                          <div className="col-md-5 text-right">
                            <span className="text-muted">
                              Active : {dashboardLists?.activeUserCount}
                            </span>{" "}
                            <br />
                            <span className="text-muted">
                              Inactive : {dashboardLists?.inactiveUserCount}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress shadow-sm"
                          style={{ height: 5 }}
                        >
                          <div
                            className="progress-bar bg-secondary w-100"
                            role="progressbar"
                          />
                        </div>
                      </div>
                      {/*end card body*/}
                    </div>
                    {/* end card*/}
                  </div>
                ) : null}
                {modulePermission()?.includes("contact") ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <span className="badge badge-soft-primary float-right">
                            Messages
                          </span>
                          <h5 className="card-title mb-0">Messages</h5>
                        </div>
                        <div className="row d-flex align-items-center mb-4">
                          <div className="col-md-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {dashboardLists?.messagesCount}
                            </h2>
                          </div>
                        </div>
                        <div
                          className="progress shadow-sm"
                          style={{ height: 5 }}
                        >
                          <div
                            className="progress-bar bg-secondary w-100"
                            role="progressbar"
                          />
                        </div>
                      </div>
                      {/*end card body*/}
                    </div>
                    {/* end card*/}
                  </div>
                ) : null}
                {modulePermission()?.includes("news") ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <span className="badge badge-soft-primary float-right">
                            News
                          </span>
                          <h5 className="card-title mb-0">
                            News
                          </h5>
                        </div>
                        <div className="row d-flex align-items-center mb-4">
                          <div className="col-md-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {
                                dashboardLists?.totalNews
                              }
                            </h2>
                          </div>
                          <div className="col-md-5 text-right">
                            <span className="text-muted">
                              Published :{" "}
                              {
                                dashboardLists?.publishedNewsCount
                              }
                            </span>{" "}
                            <br />
                            <span className="text-muted">
                            Unpublished :{" "}
                              {
                                dashboardLists?.unpublishedNewsCount
                              }
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress shadow-sm"
                          style={{ height: 5 }}
                        >
                          <div
                            className="progress-bar bg-secondary w-100"
                            role="progressbar"
                          />
                        </div>
                      </div>
                      {/*end card body*/}
                    </div>
                    {/* end card*/}
                  </div>
                ) : null}
                {/* end col*/}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Home;
