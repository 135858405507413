import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import PageTitle from '../../components/PageTitle'
import Footer from '../../components/Footer'
import JoditReact from "jodit-react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { API_BASE_IMAGE_URL, editorConfig, editorConfigAra } from "../../constants/configuration";
import { useDispatch, useSelector } from 'react-redux'
import { useGlobalFunctions } from '../../utils/useGlobalFunctions'
import { getPrivacy, insertPrivacy, updatePrivacy } from '../../redux/actionCreator'
import { getPrivacy_detail, getPrivacy_path } from '../../redux/commonReducer'
import { useNavigate } from 'react-router-dom'

export default function AddPrivacy() {

    const { imagePreview, handleImagePreview } = useGlobalFunctions();
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const privacyDetail = useSelector(getPrivacy_detail);
    const imagePath = useSelector(getPrivacy_path);
    const linkArray = [
        {
            title: "Privacy Policy",
            link: null,
        },
    ];

    useEffect(() => {
        dispatch(
            getPrivacy((data) => {
                reset({
                    titleen: data?.title,
                    titlear: data?.title_ar,
                    description: data?.description,
                    description_ar: data?.description_ar
                });
            })
        );
    }, []);
    const onSubmit = (data) => {
        console.log(data, '123')
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("title", data?.titleen);
        formData.append("title_ar", data?.titlear);
        formData.append("description", data?.description);
        formData.append("description_ar", data?.description_ar);
        formData.append("image_name", data?.banner[0]);
        dispatch(
            insertPrivacy(formData, (res) => {
                if (res === true) {
                    dispatch(
                        getPrivacy((data) => {
                            reset({
                                title: data?.title,
                                title_ar: data?.title_ar,
                                description: data?.description,
                                description_ar: data?.description_ar,
                            });
                        })
                    );
                    setIsSubmitting(false);
                    toast.success("Privacy policy added successfully", {
                        position: "bottom-center",
                        autoClose: 3000,
                    });
                }
            })
        );
    };

    const handleUpdate = (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("title", data?.titleen);
        formData.append("title_ar", data?.titlear);
        formData.append("description", data?.description);
        formData.append("description_ar", data?.description_ar);
        formData.append("image_name", data?.banner[0]);
        formData.append("id", privacyDetail._id);
        dispatch(
            updatePrivacy(formData, (res) => {
            dispatch(
                getPrivacy((data) => {
                reset({
                    title: data?.title,
                    title_ar: data?.title_ar,
                    description: data?.description,
                    description_ar: data?.description_ar,
                });
              })
            );
            if (res === true) {
              setIsSubmitting(false);
              toast.success("Privacy policy updated successfully", {
                position: "bottom-center",
                autoClose: 3000,
              });
            }
          })
        );
      };
    return (
        <>

            <Header />
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <PageTitle title="Privacy & Policy" linkArray={linkArray} />

                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <form
                                        onSubmit={
                                            privacyDetail
                                                ? handleSubmit(handleUpdate)
                                                : handleSubmit(onSubmit)
                                        }
                                    >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Title (en)</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("titleen", { required: true })}
                                                        placeholder="type here"
                                                    />
                                                    {errors.titleen?.type === "required" && (
                                                        <span className="formError">
                                                            Title (en) is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Title (ar)</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("titlear", { required: true })}
                                                        placeholder="type here"
                                                    />
                                                    {errors.titlear?.type === "required" && (
                                                        <span className="formError">
                                                            Title (ar) is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Content (en)</label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue={""}
                                                        {...register("description", { required: true })}
                                                        render={({ field }) => (
                                                            <JoditReact
                                                                config={editorConfig}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                onBlur={field.onChange}
                                                                ref={field.ref}
                                                            />
                                                        )}
                                                    />
                                                    {errors.description?.type === "required" && (
                                                        <span className="formError">
                                                            Description (en) is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Content (ar)</label>
                                                    <Controller
                                                        {...register("description_ar", { required: true })}
                                                        control={control}
                                                        defaultValue={""}
                                                        render={({ field }) => (
                                                            <JoditReact
                                                                config={editorConfigAra}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                onBlur={field.onChange}
                                                                ref={field.ref}
                                                                className="arabic"
                                                            />
                                                        )}
                                                    />
                                                    {errors.description_ar?.type === "required" && (
                                                        <span className="formError">
                                                            Description (ar) is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Banner <span className="resolution">(image size : 1110x330)</span></label>
                                                    <input
                                                        type="file"
                                                        {...register("banner", {
                                                            required: privacyDetail ? false : true,
                                                        })}
                                                        className="form-control"
                                                        placeholder="file"
                                                        accept='image/*'
                                                        onChange={(e) => handleImagePreview(e)}
                                                    />
                                                    {!imagePreview.banner
                                                        ? errors.banner?.type === "required" && (
                                                            <span className="formError">
                                                                Banner Image is required
                                                            </span>
                                                        )
                                                        : ""}

                                                    {imagePreview.banner ? (
                                                        <div className="preview__img">

                                                            <img
                                                                src={imagePreview.banner}
                                                                className="preview"
                                                                alt="Preview"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="preview__img">
                                                            {
                                                                 privacyDetail != null &&
                                                                 <img
                                                                 src={
                                                                     API_BASE_IMAGE_URL +
                                                                     imagePath +
                                                                     "/" +
                                                                     privacyDetail?.image_name
                                                                 }
                                                                 className="preview"
                                                                 alt=""
                                                             />
                                                            }
                                                           
                                                        </div>
                                                    )}

                                                </div>
                                            </div>

                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary mr-1"
                                        disabled={isSubmitting}
                                        >
                                            {privacyDetail
                                                ? isSubmitting
                                                    ? "Updating..."
                                                    : "Update"
                                                : isSubmitting
                                                    ? "Submitting..."
                                                    : "Submit"}
                                        </button>
                                        <button
                                            className="btn btn-danger"
                                            onClick={(e) => navigate('/')}
                                        >
                                            Cancel
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
