import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'commonReducer',
    initialState: {
        value: true,

    },
    reducers: {
        updateRedux: (state, data) => {
            state[data.payload.key] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const bannersLists = (state ) => state.commonReducer.bannersLists;
export const banner_details = (state ) => state.commonReducer.banner_details;
export const homebanner_details = (state ) => state.commonReducer.homebanner_details;
export const roles_list = (state ) => state.commonReducer.roles_list;
export const roles_details = (state ) => state.commonReducer.roles_details;
export const jurisdiction_list = (state ) => state.commonReducer.jurisdiction_list;
export const jurisdiction_details = (state ) => state.commonReducer.jurisdiction_details;
export const about_details = (state ) => state.commonReducer.about_details;
export const AboutValues = (state ) => state.commonReducer.AboutValues;
export const aboutValues_details = (state ) => state.commonReducer.aboutValues_details;
export const visionMission_details = (state ) => state.commonReducer.visionMission_details;
export const agreement_details = (state ) => state.commonReducer.agreement_details;
export const getContactInfo_detail = (state ) => state.commonReducer.getContactInfo_detail;
export const banner_count = (state ) => state.commonReducer.banner_count;
export const banner_page_status = (state ) => state.commonReducer.banner_page_status;
export const AboutValues_count = (state ) => state.commonReducer.AboutValues_count;
export const AboutValues_status = (state ) => state.commonReducer.AboutValues_status;
export const roleslist_count = (state ) => state.commonReducer.roleslist_count;
export const roleslist_status = (state ) => state.commonReducer.roleslist_status;
export const jurisdictionList_count = (state ) => state.commonReducer.jurisdictionList_count;
export const jurisdictionList_status = (state ) => state.commonReducer.jurisdictionList_status;
export const messageLists = (state ) => state.commonReducer.messageLists;
export const messageLists_count = (state ) => state.commonReducer.messageLists_count;
export const messageLists__status = (state ) => state.commonReducer.messageLists__status;
export const modules = (state ) => state.commonReducer.modules;
export const userList = (state ) => state.commonReducer.userList;
export const userPath = (state ) => state.commonReducer.userPath;
export const userCount = (state ) => state.commonReducer.userCount;
export const userDetails = (state ) => state.commonReducer.userDetails;
export const userPageStatus = (state ) => state.commonReducer.userPageStatus;
export const dashboardItems = (state ) => state.commonReducer.dashboardItems;
export const permissions = (state ) => state.commonReducer.permissions;
export const about_path = (state ) => state.commonReducer.about_path;
export const getContactInfo_path = (state ) => state.commonReducer.getContactInfo_path;
export const banner_path = (state ) => state.commonReducer.banner_path;
export const homebanner_path = (state ) => state.commonReducer.homebanner_path;
export const jurisdiction_path = (state ) => state.commonReducer.jurisdiction_path;
export const roles_path = (state ) => state.commonReducer.roles_path;
export const getPrivacy_detail = (state ) => state.commonReducer.getPrivacy_detail;
export const getPrivacy_path = (state ) => state.commonReducer.getPrivacy_path;
export const getTerms_detail = (state ) => state.commonReducer.getTerms_detail;
export const getTerms_path = (state ) => state.commonReducer.getTerms_path;
export const newsList = (state) => state.commonReducer.newsList;
export const newsDetail = (state) => state.commonReducer.newsDetail;
export const newsPages_status = (state) => state.commonReducer.newsPages_status;
export const newsPages_count = (state) => state.commonReducer.newsPages_count;

export default commonSlice.reducer;