import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import {
  getAboutValuesDetails,
  insertValues,
  updateAboutValues,
} from "../../redux/actionCreator";
import { Controller, useForm } from "react-hook-form";
import JoditReact from "jodit-react";
import { useEffect } from "react";
import { aboutValues_details } from "../../redux/commonReducer";
import { editorConfig, editorConfigAra } from "../../constants/configuration";
function EditValues() {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const ValueDetail = useSelector(aboutValues_details);

  const linkArray = [
    {
      title: "About Values",
      link: "/values",
    },
    {
      title: "Edit About Values",
      link: null,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(
        getAboutValuesDetails(id, (data) => {
          reset({
            titleen: data?.title_en,
            titlear: data?.title_ar,
            detailen: data?.detail_en,
            detailar: data?.detail_ar,
            status: data?.active == 1 ? "1" : "0",
          });
        })
      );
    }
  }, [id]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("detail_en", data?.detailen);
    formData.append("detail_ar", data?.detailar);
    formData.append("active", data?.status);
    formData.append("id", id);
    dispatch(
      updateAboutValues(formData, (res) => {
        navigate("/values");
        if (res === true) {
          setIsSubmitting(false);
          reset();
          toast.success("Values Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  return (
    <>
      <>
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Edit About Values" linkArray={linkArray} />
              <div className="col-xl-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (en)</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("titleen", { required: true })}
                                placeholder="type here"
                              />
                              {errors.titleen?.type === "required" && (
                                <span className="formError">
                                  Title (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (ar)</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("titlear", { required: true })}
                                placeholder="type here"
                              />
                              {errors.titlear?.type === "required" && (
                                <span className="formError">
                                  Title (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (en)</label>
                              <Controller
                                {...register("detailen", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfig}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.detailen?.type === "required" && (
                                <span className="formError">
                                  Detail (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (ar)</label>
                              <Controller
                                {...register("detailar", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfigAra}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                    className="arabic"
                                  />
                                )}
                              />
                              {errors.detailar?.type === "required" && (
                                <span className="formError">
                                  Detail (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Status</label>
                              <br />
                              <div class="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio1"
                                  value="1"
                                  {...register("status", { required: true })}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio1"
                                >
                                  Active
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio2"
                                  value="0"
                                  {...register("status", { required: true })}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio2"
                                >
                                  Inactive
                                </label>
                              </div>
                              <br />
                              {errors.status?.type === "required" && (
                                <span className="formError">
                                  Please select status
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                        <Link to="/values">
                          <button type="submit" className="btn btn-danger">
                            Cancel
                          </button>
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
}

export default EditValues;
