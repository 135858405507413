import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { insertBanners } from "../../redux/actionCreator";
import { Controller, useForm } from "react-hook-form";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";

function AddBanners() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const linkArray = [
    {
      title: "Banners",
      link: "/banners",
    },
    {
      title: "Add Banners",
      link: null,
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("image_name", data?.image[0]);
    formData.append("url_en", data?.urlen);
    formData.append("url_ar", data?.urlar);
    formData.append("active", data?.status);
    dispatch(
      insertBanners(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          toast.success("Banner added successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          navigate("/banners");
        }
      })
    );
  };
  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Add Banners" linkArray={linkArray} />
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              {imagePreview.image && (
                                <div className="preview__img">
                                  <img
                                    src={imagePreview.image}
                                    className="preview"
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Image <span className="resolution">(image size : 2160x1200)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("image", { required: true })}
                              placeholder="type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.image
                              ? errors.image?.type === "required" && (
                                  <span className="formError">
                                    Image is required
                                  </span>
                                )
                              : ""}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Title (en)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titleen", { required: true })}
                              placeholder="type here"
                            />
                            {errors.titleen?.type === "required" && (
                              <span className="formError">
                                Title (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Title (ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titlear", { required: true })}
                              placeholder="type here"
                            />
                            {errors.titlear?.type === "required" && (
                              <span className="formError">
                                Title (ar) is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>URL (En)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("urlen", { required: false })}
                              placeholder="type here"
                            />
                            {/* {errors.urlen?.type === "required" && (
                              <span className="formError">
                                URL (en) is required
                              </span>
                            )} */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>URL (Ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("urlar", { required: false })}
                              placeholder="type here"
                            />
                            {/* {errors.urlar?.type === "required" && (
                              <span className="formError">
                                URL (ar) is required
                              </span>
                            )} */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Status</label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="1"
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Active
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="0"
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Inactive
                              </label>
                            </div>
                            <br />
                            {errors.status?.type === "required" && (
                              <span className="formError">
                                Please select status
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={(e) => handleSubmit(e)}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                      <Link to="/banners">
                        <button type="submit" className="btn btn-danger">
                          Cancel
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddBanners;
