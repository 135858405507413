import React from 'react'
import { Link, useLocation } from 'react-router-dom';

function PageTitle({title,linkArray}) {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter((segment) => segment !== '');
    const breadcrumbPath = ['/'];
    return (
        <>

                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        {/* {pathSegments.map((segment, index) => {
                            breadcrumbPath.push(`/${segment}`);
                            const breadcrumbName = segment.charAt(0).toUpperCase() + segment.slice(1);

                            return (
                                <> */}
                                    <h4 className="mb-0 font-size-18">{title}</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                            {
                                                linkArray?.map((ele, i) => {
                                                    return(
                                                        <li key={i} className="breadcrumb-item">
                                                        {
                                                            ele.link 
                                                            ? <Link to={ele.link}>{ele.title}</Link>
                                                            : <Link>{ele.title}</Link>
                                                        }
                                                    </li>
                                                    )
                                                })
                                            }
                                           

                                        </ol>
                                    </div>
                                {/* </>
                            );
                        })} */}
                    </div>
                </div>
           
        </>
    )
}

export default PageTitle

