import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "../utils/PrivateRoutes";
import About from "../pages/About/About";
import AddValues from "../pages/About/AddValues";
import EditValues from "../pages/About/EditValues";
import Mission from "../pages/About/Mission";
import Values from "../pages/About/Values";
import Vision from "../pages/About/Vision";
import Info from "../pages/Contact/Info";
import Messages from "../pages/Contact/Messages";
import Home from "../pages/Dashboard/Home";
import AddBanners from "../pages/Home/AddBanners";
import Banner from "../pages/Home/Banner";
import EditBanners from "../pages/Home/EditBanners";
import HomeBanner from "../pages/Home/HomeBanner";
import AddJurisdictions from "../pages/Jurisdictions/AddJurisdictions";
import EditJurisdiction from "../pages/Jurisdictions/EditJurisdiction";
import Jurisdictions from "../pages/Jurisdictions/Jurisdictions";
import Login from "../pages/Login";
import AddRoles from "../pages/Roles/AddRoles";
import EditRole from "../pages/Roles/EditRole";
import Roles from "../pages/Roles/Roles";
import AddUsers from "../pages/Users/AddUsers";
import Users from "../pages/Users/Users";
import EditUsers from "../pages/Users/editUsers";
import Agreement from "../pages/Website/Agreement";
import NotFound from "../components/notFound";
import { permissions } from "../redux/commonReducer";
import { useSelector } from "react-redux";
import { modulePermission } from "../utils/helpers";
import AddVideoBanner from "../pages/Home/AddVideoBanner";
import EditVideoBanner from "../pages/Home/EditVideoBanner";
import AddPrivacy from "../pages/Privacy/AddPrivacy";
import AddTerms from "../pages/Privacy/AddTerms";
import News from "../pages/News/News";
import AddNews from "../pages/News/AddNews";

const RouteHandler = () => {

  const permission = useSelector(permissions);
  // const actionPermissions = JSON.parse(localStorage.getItem("permissions") || "[]")

  useEffect(() => {
  }, [permission]);

  // const onPermissionHandler = (permissionKey) => {
  //   let value = actionPermissions?.find(
  //     (e) => e.module_id?.module_slug == permissionKey
  //   );
  //   return value?.view == "1" ||
  //     value?.add == "1" ||
  //     value?.edit == "1" ||
  //     value?.delete == "1"
  //     ? true
  //     : false;
  // };

  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Home />} />
          {modulePermission()?.includes("home") ? (
            <>
              <Route path="banners" element={<Banner />} />
              <Route path="add-banners" element={<AddBanners />} />
              <Route path="add-video-banners" element={<AddVideoBanner />} />
              <Route path="edit-banners/:id" element={<EditBanners />} />
              <Route path="edit-video-banners/:id" element={<EditVideoBanner />} />
              <Route path="home" element={<HomeBanner />} />
            </>
          ) : null}
          {modulePermission()?.includes("about") ? (
            <>
              <Route path="about" element={<About />} />
              <Route path="values" element={<Values />} />
              <Route path="add-values" element={<AddValues />} />
              <Route path="edit-values/:id" element={<EditValues />} />
              <Route path="mission" element={<Mission />} />
            </>
          ) : null}
          {modulePermission()?.includes("roles_assignment") ? (
            <>
              <Route path="roles" element={<Roles />} />
              <Route path="add-roles" element={<AddRoles />} />
              <Route path="edit-roles/:id" element={<EditRole />} />
            </>
          ) : null}
          {modulePermission()?.includes("jurisdictions_authorities") ? (
            <>
              <Route path="jurisdictions" element={<Jurisdictions />} />
              <Route path="add-jurisdictions" element={<AddJurisdictions />} />
              <Route
                path="edit-jurisdictions/:id"
                element={<EditJurisdiction />}
              />
            </>
          ) : null}
          {modulePermission()?.includes("contact") ? (
            <>
              <Route path="info" element={<Info />} />
              <Route path="messages" element={<Messages />} />
            </>
          ) : null}
          {modulePermission()?.includes("users") ? (
            <>
              <Route path="users" element={<Users />} />
              <Route path="add-users" element={<AddUsers />} />
              <Route path="edit-users/:id" element={<EditUsers />} />
            </>
          ) : null}
        </Route>
        {
          modulePermission()?.includes("incident_terms")
            ? <Route path="agreement" element={<Agreement />} />
            : null
        }

        {modulePermission()?.includes("news") ?
          <>
            <Route path="news" element={<News />} />
            <Route path="news/add_news" element={<AddNews />} />
            <Route path="news/edit_news/:id" element={<AddNews />} />
          </>
          : null
        }

        {modulePermission()?.includes("privacy") ?
          <>
            <Route path="privacy" element={<AddPrivacy />} />
          </>
          : null
        }
        {modulePermission()?.includes("terms_conditions") ?
          <>
             <Route path="terms" element={<AddTerms />} />
          </>
          : null
        }

       

        <Route path="login" element={<Login />} />
        <Route path="*" element={<NotFound />} />

      </Routes>
    </>
  );
};

export default RouteHandler;
