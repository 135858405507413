import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { insertBanners } from "../../redux/actionCreator";
import { Controller, useForm } from "react-hook-form";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";

function AddVideoBanner() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const linkArray = [
    {
      title: "Banners",
      link: "/banners",
    },
    {
      title: "Add Banners",
      link: null,
    },
  ];

  const onSubmit = (data) => {
    console.log({errors})
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("desc", data?.descrptionen);
    formData.append("desc_ar", data?.descriptionar);
    formData.append("image_name", data?.background[0]);
    formData.append("thumb_image", data?.thumbnail[0]);
    formData.append("video_url", data?.urlen);
    formData.append("video_url_ar", data?.urlar);
    formData.append("active", 1);
    dispatch(
      insertBanners(formData, (res) => {
        setIsSubmitting(false);
        if (res === true) {
          setIsSubmitting(false);
          toast.success("Banner added successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          navigate("/banners");
        }
      })
    );
    console.log({ data })
  };
  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Add Video Banner" linkArray={linkArray} />
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              {imagePreview.image && (
                                <div className="preview__img">
                                  <img
                                    src={imagePreview.image}
                                    className="preview"
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Image <span className="resolution">(image size : 2160x1200)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("image", { required: true })}
                              placeholder="Type Here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.image
                              ? errors.image?.type === "required" && (
                                  <span className="formError">
                                    Image is required
                                  </span>
                                )
                              : ""}
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title (En) <span className="required">*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titleen", { required: true })}
                              placeholder="Type Here"
                            />
                            {errors.titleen?.type === "required" && (
                              <span className="formError">
                                Title (En) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title (Ar)<span className="required">*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titlear", { required: true })}
                              placeholder="Type Here"
                            />
                            {errors.titlear?.type === "required" && (
                              <span className="formError">
                                Title (Ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Description (En) (400 characters maximum)<span className="required">*</span></label>
                            <textarea
                              type="text"
                              className="form-control"
                              {...register("descrptionen", { required: true, maxLength: 400 })}
                              placeholder="Type Here"
                            />
                            {errors.descrptionen?.type === "required" && (
                              <span className="formError">
                                Description (En) is required
                              </span>
                            )}
                            {errors.descrptionen?.type === "maxLength" && (
                              <span className="formError">
                                Description (En) exceeded 400 characters
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Description (Ar) (400 characters maximum)<span className="required">*</span></label>
                            <textarea
                              type="text"
                              className="form-control"
                              {...register("descriptionar", { required: true, maxLength: 400 })}
                              placeholder="Type Here"
                            />
                            {errors.descriptionar?.type === "required" && (
                              <span className="formError">
                                Description (Ar) is required
                              </span>
                            )}
                             {errors.descriptionar?.type === "maxLength" && (
                              <span className="formError">
                                Description (En) exceeded 400 characters
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Upload Thumbnail Image <span className="resolution">(Dimension : 800x400)</span><span className="required">*</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("thumbnail", { required: true })}
                              placeholder="Type Here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.image
                              ? errors.thumbnail?.type === "required" && (
                                <span className="formError">
                                  Thumbnail Image is required
                                </span>
                              )
                              : ""}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Upload Background Image <span className="resolution">(Dimension : 21600x1200)</span><span className="required">*</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("background", { required: true })}
                              placeholder="Type Here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {!imagePreview.image
                              ? errors.background?.type === "required" && (
                                <span className="formError">
                                  Background Image is required
                                </span>
                              )
                              : ""}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Youtube URL (En)<span className="required">*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("urlen", { required: true })}
                              placeholder="Youtube URL"
                            />
                            {errors.urlen?.type === "required" && (
                              <span className="formError">
                                URL (En) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Youtube URL (Ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("urlar", { required: false })}
                              placeholder="Youtube URL"
                            />
                            {errors.urlar?.type === "required" && (
                              <span className="formError">
                                URL (Ar) is required
                              </span>
                            )}
                          </div>
                        </div>

                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={(e) => handleSubmit(e)}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                      <Link to="/banners">
                        <button type="submit" className="btn btn-danger">
                          Cancel
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddVideoBanner;
