

import { useState } from 'react';

export const useGlobalFunctions = () => {
    const [imagePreview, setImagePreview] = useState([])
  
    const handleImagePreview = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImagePreview(prevPreview => ({ ...prevPreview, [e.target.name]: reader.result }));
        };
      };
      
  
    return { imagePreview, handleImagePreview };
  };