import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import JoditReact from "jodit-react";
import { editorConfig, editorConfigAra } from "../../constants/configuration";
import {
  getVisionMission,
  insertVisionMission,
  updateVisionMission,
} from "../../redux/actionCreator";
import { visionMission_details } from "../../redux/commonReducer";
import { useEffect } from "react";
import { useState } from "react";

function Mission() {
  const dispatch = useDispatch();
  const navigate= useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const visionDetails = useSelector(visionMission_details);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const linkArray = [
    {
      title: "Mission and Vission",
      link: null,
    },
  ];

  useEffect(() => {
    dispatch(
      getVisionMission((data) => {
        reset({
          titleen: data?.mission_title_en,
          titlear: data?.mission_title_ar,
          detail_en: data?.mission_detail_en,
          detail_ar: data?.mission_detail_ar,

          v_titleen: data?.vision_title_en,
          v_titlear: data?.vision_title_ar,
          v_detail_en: data?.vision_detail_en,
          v_detail_ar: data?.vision_detail_ar,
        });
      })
    );
  }, []);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("vision_title_en", data?.v_titleen);
    formData.append("vision_title_ar", data?.v_titlear);
    formData.append("vision_detail_en", data?.v_detail_en);
    formData.append("vision_detail_ar", data?.v_detail_ar);
    formData.append("mission_title_en", data?.titleen);
    formData.append("mission_title_ar", data?.titlear);
    formData.append("mission_detail_en", data?.detail_en);
    formData.append("mission_detail_ar", data?.detail_ar);
    dispatch(
      insertVisionMission(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          dispatch(
            getVisionMission((data) => {
              reset({
                titleen: data?.mission_title_en,
                titlear: data?.mission_title_ar,
                detail_en: data?.mission_detail_en,
                detail_ar: data?.mission_detail_ar,
      
                v_titleen: data?.vision_title_en,
                v_titlear: data?.vision_title_ar,
                v_detail_en: data?.vision_detail_en,
                v_detail_ar: data?.vision_detail_ar,
              });
            })
          );
          toast.success("Mission & Vision added successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const onUpdate = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("vision_title_en", data?.v_titleen);
    formData.append("vision_title_ar", data?.v_titlear);
    formData.append("vision_detail_en", data?.v_detail_en);
    formData.append("vision_detail_ar", data?.v_detail_ar);
    formData.append("mission_title_en", data?.titleen);
    formData.append("mission_title_ar", data?.titlear);
    formData.append("mission_detail_en", data?.detail_en);
    formData.append("mission_detail_ar", data?.detail_ar);
    formData.append("id", visionDetails?._id);
    dispatch(
      updateVisionMission(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          dispatch(
            getVisionMission((data) => {
              reset({
                titleen: data?.mission_title_en,
                titlear: data?.mission_title_ar,
                detail_en: data?.mission_detail_en,
                detail_ar: data?.mission_detail_ar,
      
                v_titleen: data?.vision_title_en,
                v_titlear: data?.vision_title_ar,
                v_detail_en: data?.vision_detail_en,
                v_detail_ar: data?.vision_detail_ar,
              });
            })
          );
          toast.success("Mission & Vision Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const dontSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <>
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Mission and Vission" linkArray={linkArray} />
              <div className="col-xl-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <form
                        onSubmit={
                          visionDetails
                            ? handleSubmit(onUpdate)
                            : handleSubmit(onSubmit)
                        }
                      >
                        <div className="form__heading">
                          <h4>Mission</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (en)</label>
                              <input
                                type="text"
                                {...register("titleen", { required: true })}
                                className="form-control"
                                placeholder="type here"
                              />
                              {errors.titleen?.type === "required" && (
                                <span className="formError">
                                  Title (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (ar)</label>
                              <input
                                type="text"
                                {...register("titlear", { required: true })}
                                className="form-control"
                                placeholder="type here"
                              />
                              {errors.titlear?.type === "required" && (
                                <span className="formError">
                                  Title (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (en)</label>
                              <Controller
                                {...register("detail_en", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfig}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.detail_en?.type === "required" && (
                                <span className="formError">
                                  Detail (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (ar)</label>
                              <Controller
                                {...register("detail_ar", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfigAra}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    className="arabic"
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.detail_ar?.type === "required" && (
                                <span className="formError">
                                  Detail (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="form__heading">
                          <h4>Vision</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (en)</label>
                              <input
                                type="text"
                                {...register("v_titleen", { required: true })}
                                className="form-control"
                                placeholder="type here"
                              />
                              {errors.v_titleen?.type === "required" && (
                                <span className="formError">
                                  Vision Title (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Title (ar)</label>
                              <input
                                type="text"
                                {...register("v_titlear", { required: true })}
                                className="form-control"
                                placeholder="type here"
                              />
                              {errors.v_titlear?.type === "required" && (
                                <span className="formError">
                                  Vision Title (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (en)</label>
                              <Controller
                                {...register("v_detail_en", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfig}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.v_detail_en?.type === "required" && (
                                <span className="formError">
                                  Vision Detail (en) is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Detail (ar)</label>
                              <Controller
                                {...register("v_detail_ar", { required: true })}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                  <JoditReact
                                    config={editorConfigAra}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onChange}
                                    className="arabic"
                                    ref={field.ref}
                                  />
                                )}
                              />
                              {errors.v_detail_ar?.type === "required" && (
                                <span className="formError">
                                  Vision Detail (ar) is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          disabled={isSubmitting}
                        >
                          {visionDetails
                            ? isSubmitting
                              ? "Updating..."
                              : "Update"
                            : isSubmitting
                            ? "Submitting..."
                            : "Submit"}
                        </button>
                        <button className="btn btn-danger" onClick={()=>navigate('/')}>
                          Cancel
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
}

export default Mission;
