import React from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../redux/actionCreator';

function Login() {
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onSubmit = (data) => {
        let loginData = {
            username:data?.username,
            password: data?.password
          };
        dispatch(login(loginData, (res) => {
            if (res === true) {
                navigate('/')
                toast.success('Login successfully', {
                    position: "bottom-center",
                    autoClose: 3000
                })
            }
        })
        );
    };
    return (
        <div className="container-fluid pl-0">
            <div className="row min-vh-100">
                <div className="col-md-5">
                    <div className="hero-wrap h-100 bg-primary">
                        <div className="hero-content w-100">
                            <div className="container d-flex flex-column min-vh-100">
                                <div className="row g-0 login-index">
                                    <div className="col-11 col-md-10 col-lg-9 mx-auto">
                                        <div className="login__logo mt-5 mb-5 mb-md-0">
                                            <a className="d-flex"  title="Economic Security">
                                                <img src="./assets/img/logo.svg" alt="Economic Security" />
                                            </a> </div>
                                    </div>
                                </div>
                                <div className="row g-0 my-auto login-index">
                                    <div className="col-11 col-md-10 col-lg-9 mx-auto">
                                        <div className="login__content">
                                            <p>We are glad to see you again!</p>
                                            <h1>Welcome to ESC Admin dashboard.</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-7 d-flex flex-column">
                    <div className="container my-auto py-5">
                        <div className="row g-0">
                            <div className="col-11 col-md-10 col-lg-9 col-xl-8 mx-auto">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" {...register("username", { required: true })} className="form-control" placeholder='Username' />
                                        {errors.username?.type === 'required' && <span className='formError'>Username is required</span>}
                                    </div>
                                </div>
                                <div className="mb-3">
                                <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" {...register("password", { required: true })} className="form-control" placeholder='Password' />
                                        {errors.password?.type === 'required' && <span className='formError'>Password is required</span>}
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="submit">Sign in</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login