import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteHandler from "./router/router";

function App() {

  return (
    (
      <>
        <HashRouter>
          <ToastContainer />
          <RouteHandler />
        </HashRouter>
      </>
    )
  );
}

export default App;
