import React from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import JoditReact from "jodit-react";
import "jodit/build/jodit.min.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getRolesAssignDetails,
  insertRoles,
  updateRolesAssign,
} from "../../redux/actionCreator";

import { Controller, useForm } from "react-hook-form";
import { API_BASE_IMAGE_URL, editorConfig, editorConfigAra } from "../../constants/configuration";
import { roles_details, roles_path } from "../../redux/commonReducer";
import { useEffect } from "react";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";
function EditRole() {
  const { imagePreview, handleImagePreview } = useGlobalFunctions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const rolesDetails = useSelector(roles_details);
  const rolesPath = useSelector(roles_path);

  const linkArray = [
    {
      title: "Roles & Assignments",
      link: "/roles",
    },
    {
      title: "Edit Roles",
      link: null,
    },
  ];

  const handleUpdate = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title_en", data?.titleen);
    formData.append("title_ar", data?.titlear);
    formData.append("sub_title_en", data?.subTitleen);
    formData.append("sub_title_ar", data?.subTitlear);
    formData.append("detail_en", data?.detail_en);
    formData.append("detail_ar", data?.detail_ar);
    formData.append("image_name", data?.image[0]);
    formData.append("banner_name", data?.banner[0]);
    formData.append("title_icon_name", data?.titleIcon[0]);
    formData.append("body_icon_name", data?.bodyIcon[0]);
    formData.append("active", data?.status);
    formData.append("id", id);
    dispatch(
      updateRolesAssign(formData, (res) => {
        if (res === true) {
          setIsSubmitting(false);
          navigate("/roles");
          toast.success("Role Updated successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(
        getRolesAssignDetails(id, (data) => {
          reset({
            titleen: data?.title_en,
            titlear: data?.title_ar,
            subTitleen: data?.sub_title_en,
            subTitlear: data?.sub_title_ar,
            detail_en: data?.detail_en,
            detail_ar: data?.detail_ar,
            status: data?.active == 1 ? "1" : "0",
          });
        })
      );
    }
  }, [id]);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <PageTitle title="Edit Roles & Assignments" linkArray={linkArray} />

            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit(handleUpdate)}>
                      <div className="row">
                        {rolesDetails && (
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-3 pl-0">
                                {imagePreview.banner ? (
                                  <div className="preview__img">
                                    <img
                                      src={imagePreview.banner}
                                      className="preview"
                                      alt="Preview"
                                    />
                                  </div>
                                ) : (
                                  <div className="preview__img">
                                    <img
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath +
                                        "/" +
                                        rolesDetails?.banner_name
                                      }
                                      className="preview"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-md-3">
                                {imagePreview.bodyIcon ? (
                                  <div className="preview__img">
                                    <img
                                      src={imagePreview.bodyIcon}
                                      className="preview"
                                      alt="Preview"
                                    />
                                  </div>
                                ) : (
                                  <div className="preview__img">
                                    <img
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath +
                                        "/" +
                                        rolesDetails?.body_icon_name
                                      }
                                      className="preview"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-md-3">
                                {imagePreview.image ? (
                                  <div className="preview__img">
                                    <img
                                      src={imagePreview.image}
                                      className="preview"
                                      alt="Preview"
                                    />
                                  </div>
                                ) : (
                                  <div className="preview__img">
                                    <img
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath +
                                        "/" +
                                        rolesDetails?.image_name
                                      }
                                      className="preview"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="col-md-3">
                                {imagePreview.titleIcon ? (
                                  <div className="preview__img">
                                    <img
                                      src={imagePreview.titleIcon}
                                      className="preview"
                                      alt="Preview"
                                    />
                                  </div>
                                ) : (
                                  <div className="preview__img">
                                    <img
                                      src={
                                        API_BASE_IMAGE_URL +
                                        rolesPath +
                                        "/" +
                                        rolesDetails?.title_icon_name
                                      }
                                      className="preview"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>

                            </div>
                          </div>
                        )}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Main Banner (Horizontal) <span className="resolution">(image size : 1110x330)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("banner", {
                                required: rolesDetails ? false : true,
                              })}
                              placeholder="type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {errors.banner?.type === "required" && (
                              <span className="formError">
                                Banner Image is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Banner icon for tab <span className="resolution">(image size : 100x100)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("bodyIcon", {
                                required: rolesDetails ? false : true,
                              })}
                              placeholder="type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {errors.bodyIcon?.type === "required" && (
                              <span className="formError">
                                Banner Icon is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Image for description <span className="resolution">(image size : 570x570)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("image", {
                                required: rolesDetails ? false : true,
                              })}
                              placeholder="type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {errors.image?.type === "required" && (
                              <span className="formError">
                                Image is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Title icon for description <span className="resolution">(image size : 100x100)</span></label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("titleIcon", {
                                required: rolesDetails ? false : true,
                              })}
                              placeholder="type here"
                              onChange={(e) => handleImagePreview(e)}
                            />
                            {errors.titleIcon?.type === "required" && (
                              <span className="formError">
                                Title Icon is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title (en)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titleen", { required: true })}
                              placeholder="type here"
                            />
                            {errors.titleen?.type === "required" && (
                              <span className="formError">
                                Title (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title (ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("titlear", { required: true })}
                              placeholder="type here"
                            />
                            {errors.titlear?.type === "required" && (
                              <span className="formError">
                                Title (ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Sub Title (en)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("subTitleen", { required: true })}
                              placeholder="type here"
                            />
                            {errors.subTitleen?.type === "required" && (
                              <span className="formError">
                                Subtitle (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Sub Title (ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("subTitlear", { required: true })}
                              placeholder="type here"
                            />
                            {errors.subTitlear?.type === "required" && (
                              <span className="formError">
                                Subtitle (ar) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Detail (en)</label>
                            <Controller
                              {...register("detail_en", { required: true })}
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfig}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                />
                              )}
                            />
                            {errors.detail_en?.type === "required" && (
                              <span className="formError">
                                Detail (en) is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Detail (ar)</label>
                            <Controller
                              {...register("detail_ar", { required: true })}
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <JoditReact
                                  config={editorConfigAra}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onChange}
                                  ref={field.ref}
                                  className="arabic"
                                />
                              )}
                            />
                            {errors.detail_ar?.type === "required" && (
                              <span className="formError">
                                Detail (ar) is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Status</label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="1"
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Active
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="0"
                                {...register("status", { required: true })}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Inactive
                              </label>
                            </div>
                            <br />{" "}
                            {errors.status?.type === "required" && (
                              <span className="formError">
                                Status is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                      <Link to="/roles">
                        <button
                          className="btn btn-danger"
                          onClick={(e) => e.preventDefault}
                        >
                          Cancel
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default EditRole;
