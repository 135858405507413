import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import { API_BASE_IMAGE_URL, editorConfig, editorConfigAra } from "../../constants/configuration";
import {
    addNews, deletePreview, fileUploader, getNewsDetail, updateNews,
    uploadVideo,
} from "../../redux/actionCreator";
import { homebanner_details, homebanner_path } from "../../redux/commonReducer";
import { useGlobalFunctions } from "../../utils/useGlobalFunctions";
import JoditReact from "jodit-react";
import { Controller, useForm } from "react-hook-form";
import './News.css'
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableList from "./SortableList";

function AddNews() {

    const { id } = useParams()
    const location = useLocation()

    const navigate = useNavigate()

    const [thumbnail, setThumbnail] = useState()
    const [files, setFiles] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [videourl, setVideoUrl] = useState('');
    const [deletedFiles, setDeletedFiles] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [errs, setErrs] = useState({})

    const randomTrackID = localStorage.getItem('trackID');


    const { imagePreview, handleImagePreview } = useGlobalFunctions();
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();
    const dispatch = useDispatch();
    const bannerDetail = useSelector(homebanner_details);
    const homeBannerPath = useSelector(homebanner_path);
    // const bannerDetail = null

    // RANDOM TRACKID
    function generateTrackID(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let trackID = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            trackID += characters.charAt(randomIndex);
        }
        return localStorage.setItem('trackID', trackID);

    }
    useEffect(() => {
        generateTrackID(8)
    }, [location.pathname === '/news/add_news'])

    const linkArray = [
        {
            title: "News",
            link: '/news',
        },
        {
            title: id ? "Edit News" : "Add News",
            link: null,
        },
    ];


    const onSubmit = (data) => {
        if (!thumbnail) {
            setErrs({ thumbnail: 'Thumbnail required' })
            return
        }
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('track_id', randomTrackID);
        formData.append("title_en", data?.titleen);
        formData.append("title_ar", data?.titlear);
        files.forEach(img => {
            formData.append("image_name", img);
        })
        formData.append("detail_en", data?.detail_en);
        formData.append("detail_ar", data?.detail_ar);
        formData.append("news_date", data?.date);
        formData.append("thumbnail", thumbnail[0]);
        uploadedFiles?.forEach((ele, i) => {
            formData.append(`gallery_position[${i}]`, i);
            formData.append(`gallery_id[${i}]`, ele?._id);
        });
        dispatch(
            addNews(formData, (res) => {
                if (res === true) {
                    setIsSubmitting(false);
                    navigate('/news')
                    toast.success("News added successfully", {
                        position: "bottom-center",
                        autoClose: 3000,
                    });
                }
            })
        );
    };

    useEffect(() => {
        if (id) {
            dispatch(
                getNewsDetail(id, (data) => {
                    reset({
                        titleen: data?.title_en,
                        titlear: data?.title_ar,
                        detail_en: data?.detail_en,
                        detail_ar: data?.detail_ar,
                        date: moment(data?.news_date).format('YYYY-MM-DD'),
                    });
                    console.log(data?.gallery, '1234')
                    let gallery = data?.gallery ?? [];
                    console.log(gallery);
                    // const sortedFiles = gallery.sort((a, b) => a.position - b.position);
                    // console.log(sortedFiles,'sortedFiles');
                    setUploadedFiles(gallery)
                    // let res = data?.image_name.map(img => {
                    //     return {
                    //         url: `${API_BASE_IMAGE_URL}news/${img}`,
                    //         isEdit: true,
                    //         fileName: img
                    //     }
                    // })
                    // setFiles(res)
                    setThumbnail({
                        isEdit: true,
                        url: `${API_BASE_IMAGE_URL}news/${data.thumbnail}`
                    })
                })
            );
        }
    }, []);


    const onUpdate = (data) => {
        setIsSubmitting(true);
        const formData = new FormData();

        formData.append("title_en", data?.titleen);
        formData.append("title_ar", data?.titlear);
        files.forEach(img => {
            !img?.isEdit && formData.append("image_name", img);
        })
        deletedFiles.forEach((file, i) => {
            formData.append(`deleted_gallery[${i}]`, file);
        })
        uploadedFiles?.forEach((ele, i) => {
            if (!ele?.track_id) {
                formData.append(`prev_gallery_position[${i}]`, i);
                formData.append(`prev_gallery_id[${i}]`, ele?._id);
            }
        });
        let tack_id = randomTrackID
        uploadedFiles?.forEach((ele, i) => {
            if (ele?.track_id) {
                tack_id = ele?.track_id;
                formData.append(`gallery_position[${i}]`, i);
                formData.append(`gallery_id[${i}]`, ele?._id);
            }

        });

        formData.append('track_id', tack_id);
        formData.append("detail_en", data?.detail_en);
        formData.append("detail_ar", data?.detail_ar);
        formData.append("news_date", data?.date);
        formData.append("thumbnail", thumbnail[0]);
        formData.append("id", id);

        dispatch(
            updateNews(formData, (res) => {
                if (res === true) {
                    setIsSubmitting(false);
                    dispatch(
                        getNewsDetail(id, (data) => {
                            reset({
                                titleen: data?.title_en,
                                titlear: data?.title_ar,
                                detail_en: data?.detail_en,
                                detail_ar: data?.detail_ar,
                                date: data?.news_date,
                            });
                        })
                    );
                    navigate('/news')
                    toast.success("News updated successfully", {
                        position: "bottom-center",
                        autoClose: 3000,
                    });
                }
            })
        );
    };
    const dontSubmit = (e) => {
        setIsSubmitting(false);
        e.preventDefault();
    };

    function fileCloseHandler(index, file) {
        file?.isEdit && setDeletedFiles(s => [...s, file?.fileName])
        let res = Array.from(files).filter((a, i) => {
            return !(i === index)
        })
        setFiles(res)
    }

    const handleFileUpload = async (event) => {
        setUploadLoader(true)
        const filesLists = event.target.files;
        const formData = new FormData();

        formData.append('track_id', localStorage.getItem('trackID'));

        Array.from(filesLists).forEach(fi => {
            formData.append('image_name', fi);
        })

        fileUploader(formData, (res) => {
            setUploadLoader(false)
            setUploadedFiles([...uploadedFiles, ...res?.gallery]);
        })
    };

    function getId(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);

        if (match && match[2].length == 11) {
            setVideoUrl('https://www.youtube.com/embed/' + match[2])
        } else {
            return 'error';
        }
    }

    const handleAddVideoUrl = () => {
        const formData = new FormData();
        if(videourl){
            formData.append('track_id', localStorage.getItem('trackID'));
            formData.append('video_url', videourl);
            uploadVideo(formData, (res) => {
                setVideoUrl('')
                // setUploadedFiles('')
                setUploadedFiles([...uploadedFiles, ...res?.gallery]);
            })
        }

    }
    const handleDelete = (file) => {
        console.log(file)
        const data = {
            del_id: file?._id,
            track_id: file?.track_id ? file?.track_id : randomTrackID
        }
        let record_id = file?._id;
        setDeletedFiles([...deletedFiles, file?._id])
        deletePreview(data, (res) => {
            // setUploadedFiles([...uploadedFiles, ...res?.gallery]);
            setUploadedFiles(uploadedFiles.filter(file => file._id !== record_id));
        })
    }



    return (
        console.log(uploadedFiles, 'uploadedFiles'),
        <>
            <Header />
            <Sidebar />
            <div className="main-content add_news">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <PageTitle title={id ? "Edit News" : "Add News"} linkArray={linkArray} />
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form
                                            onSubmit={
                                                id
                                                    ? handleSubmit(onUpdate)
                                                    : handleSubmit(onSubmit)
                                            }
                                        >

                                            <div className="col-md-2 p-0">
                                                <div className="form-group">
                                                    <label>Publish Date</label>
                                                    {/* <input
                                                        type="date"
                                                        className="form-control"
                                                        {...register("date", { required: true })}
                                                        placeholder="Type Here"
                                                    /> */}
                                                    <Controller
                                                        {...register("date", { required: true })}
                                                        control={control}
                                                        name="date"
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <ReactDatePicker
                                                                showIcon
                                                                icon={'mdi mdi-calendar-blank-outline'}
                                                                className="form-control"
                                                                selected={field.value}
                                                                placeholderText="Select date"
                                                                onChange={field.onChange}
                                                            />
                                                        )}
                                                    />
                                                    {errors.date?.type === "required" && (
                                                        <span className="formError">
                                                            Date is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Title (en)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...register("titleen", { required: true })}
                                                            placeholder="Type Here"
                                                        />
                                                        {errors.titleen?.type === "required" && (
                                                            <span className="formError">
                                                                Title (en) is required
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Title (ar)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...register("titlear", { required: true })}
                                                            placeholder="Type Here"
                                                        />
                                                        {errors.titlear?.type === "required" && (
                                                            <span className="formError">
                                                                Title (ar) is required
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Description (en)</label>
                                                        <Controller
                                                            {...register("detail_en", { required: true })}
                                                            control={control}
                                                            defaultValue={""}
                                                            render={({ field }) => (
                                                                <JoditReact
                                                                    config={editorConfig}
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    onBlur={field.onChange}
                                                                    ref={field.ref}
                                                                />
                                                            )}
                                                        />
                                                        {errors.detail_en?.type === "required" && (
                                                            <span className="formError">
                                                                Detail (en) is required
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Description (ar)</label>
                                                        <Controller
                                                            {...register("detail_ar", { required: true })}
                                                            control={control}
                                                            defaultValue={""}
                                                            render={({ field }) => (
                                                                <JoditReact
                                                                    config={editorConfigAra}
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    onBlur={field.onChange}
                                                                    ref={field.ref}
                                                                    className="arabic"
                                                                />
                                                            )}
                                                        />
                                                        {errors.detail_ar?.type === "required" && (
                                                            <span className="formError">
                                                                Detail (ar) is required
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>URL (ar)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("url_ar", { required: false })}
                              placeholder="Type Here"
                            />
                            {errors.url_ar?.type === "required" && (
                              <span className="formError">
                                URL (ar) is required
                              </span>
                            )}
                          </div>
                        </div> */}
                                                {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>URL (en)</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("url_en", { required: false })}
                              placeholder="Type Here"
                            />
                            {errors.url_en?.type === "required" && (
                              <span className="formError">
                                URL (en) is required
                              </span>
                            )}
                          </div>
                        </div> */}
                                            </div>
                                            <div className="col-md-4 p-0">
                                                <div className="form-group">
                                                    <label className="moreFiles" htmlFor="add_thumbnail">{id ? 'Change Thumbnail' : 'Add Thumbnail'} <span className="resolution"></span></label><br />
                                                    <span className="resolution">(recommended size : 560x250)</span>
                                                    <input
                                                        hidden
                                                        id="add_thumbnail"
                                                        type="file"
                                                        {...register("thumbnail", {
                                                            // required: id ? false : true,
                                                        })}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setErrs({ thumbnail: '' })
                                                            if(e?.target?.files?.length!=0){ 
                                                                setThumbnail(e.target.files)
                                                            }
                                                           
                                                        }}
                                                        accept=".jpg, .jpeg, .png"
                                                    // multiple
                                                    />
                                                    <br />
                                                    {errs.thumbnail && (
                                                        <span className="formError">
                                                            {errs.thumbnail}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {/* {thumbnail && (thumbnail.isEdit
                                                ? <img className="thumbnail_preview" src={thumbnail.url} alt='' />
                                                : <img className="thumbnail_preview" src={URL?.createObjectURL(thumbnail[0])} alt='' />
                                            )} */}
                                            {thumbnail && (thumbnail.isEdit
                                                ? <img className="thumbnail_preview" src={thumbnail.url} alt='' />
                                                : (thumbnail[0] instanceof File || thumbnail[0] instanceof Blob)
                                                    ? <img className="thumbnail_preview" src={URL.createObjectURL(thumbnail[0])} alt='' />
                                                    : <span>No image available</span>
                                            )}
                                            {/* <div className="col-md-4 p-0">
                                                <div className="form-group">
                                                    <label className="moreFiles" htmlFor="more-images" >Add More Images +</label> <br />
                                                    <span className="resolution">(recommended size : 1200x600)</span>
                                                    <input
                                                        hidden
                                                        id="more-images"
                                                        type="file"
                                                        {...register("logo", {
                                                            // required: bannerDetail ? false : true,
                                                        })}
                                                        className="form-control"
                                                        onChange={(e) => setFiles(s => [...s, ...e.target.files])}
                                                        multiple
                                                        accept=".jpg, .jpeg, .png"
                                                    />
                                                    {errors.logo?.type === "required" && (
                                                        <span className="formError">
                                                            Images required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="files mb-3">
                                                {Array.from(files).map((file, i) => {
                                                    return (
                                                        file?.isEdit
                                                            ? <span>
                                                                <span className="close_icon" onClick={() => fileCloseHandler(i, file)}>🗙</span>
                                                                <img src={file.url} alt='' />
                                                            </span>
                                                            : <span>
                                                                <span className="close_icon" onClick={() => fileCloseHandler(i, file)}>🗙</span>
                                                                <img src={URL.createObjectURL(file)} alt='' />
                                                            </span>
                                                    )
                                                })}
                                            </div> */}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label >Add More Images +</label>
                                                    <div className="upload_loader">

                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={handleFileUpload}
                                                            multiple
                                                            accept=".jpg, .jpeg, .png"
                                                        />
                                                        {
                                                            uploadLoader && <div class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label >Add Youtube Url</label>
                                                    <div className="d-flex" style={{ gap: "10px" }}>
                                                        <input
                                                            type="text"
                                                            placeholder="https://"
                                                            className="form-control"
                                                            onChange={(e) => getId(e.target.value)}
                                                            value={videourl ? videourl : ""}
                                                        />

                                                        <div className="btn btn-primary" disabled={videourl ? false : true} onClick={handleAddVideoUrl}>Add</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3 p-0">
                                                {uploadedFiles?.length > 0 && (
                                                    <div>

                                                        <div className="uploaded__images">
                                                            <h3>Uploaded Files</h3>
                                                            <SortableList
                                                                setUploadedFiles={setUploadedFiles}
                                                                uploadedFiles={uploadedFiles}
                                                                handleDelete={handleDelete}
                                                                API_BASE_IMAGE_URL={API_BASE_IMAGE_URL}
                                                                randomTrackID={uploadedFiles[0]?.track_id ?? ''}
                                                            />

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mr-1"
                                                disabled={isSubmitting}
                                            >
                                                {id
                                                    ? isSubmitting
                                                        ? "Updating..."
                                                        : "Update"
                                                    : isSubmitting
                                                        ? "Submitting..."
                                                        : "Submit"}
                                            </button>
                                            <button className="btn btn-danger" onClick={(e) => isSubmitting ? dontSubmit(e) : navigate('/news')}>
                                                Cancel
                                            </button>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer />
            </div >
        </>
    );
}

export default AddNews;
