import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import PageTitle from '../../components/PageTitle'
import Sidebar from '../../components/Sidebar'
import { insertValues } from '../../redux/actionCreator'
import { Controller, useForm } from 'react-hook-form'
function AddValues() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register,reset, handleSubmit, formState: { errors }, control } = useForm();

    const linkArray = [
        {
          title : "About Values",
          link : "/values"
        },
        {
          title : "Add About Values",
          link : null
        }
      ]

    const onSubmit = (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('title_en', data?.titleen);
        formData.append('title_ar', data?.titlear);
        formData.append('detail_en', data?.detailen);
        formData.append('detail_ar', data?.detailar);
        formData.append('active', data?.status);
        dispatch(insertValues(formData, (res) => {
            if (res === true) {
                setIsSubmitting(false);
                navigate('/values')
                reset()
                toast.success('Values added successfully', {
                    position: "bottom-center",
                    autoClose: 3000
                })
            }
        })
        );
    };
    return (
        <>
            <>
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageTitle title = "Add About Values" linkArray = {linkArray}/>

                            <div className="col-xl-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="basic-form">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Title (en)</label>
                                                        <input type="text" className="form-control" {...register('titleen',{ required: true })} placeholder='type here' />
                                                        {errors.titleen?.type === 'required' && <span className='formError'>Title (en) is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Title (ar)</label>
                                                        <input type="text" className="form-control" {...register('titlear',{ required: true })} placeholder='type here' />
                                                        {errors.titlear?.type === 'required' && <span className='formError'>Title (ar) is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Detail (en)</label>
                                                        <textarea className="form-control" cols="30" rows="10" {...register('detailen',{ required: true })} placeholder='type here'></textarea>
                                                        {errors.detailen?.type === 'required' && <span className='formError'>Detail (en) is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Detail (ar)</label>
                                                        <textarea className="form-control" cols="30" rows="10" {...register('detailar',{ required: true })} placeholder='type here'></textarea>
                                                        {errors.detailar?.type === 'required' && <span className='formError'>Detail (ar) is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Status</label><br />
                                                        <div class="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1"
                                                               {...register('status',{ required: true })}
                                                            />
                                                            <label class="form-check-label" for="inlineRadio1">Active</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="0"
                                                               {...register('status',{ required: true })}
                                                            />
                                                            <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                                        </div>
                                                        <br/>{errors.status?.type === 'required' && <span className='formError'>Please select status</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary mr-1" disabled={isSubmitting}>
                                            {isSubmitting ? 'Submiting...' : 'Submit'}
                                            </button>
                                            <Link to='/values'>
                                            <button type="submit" className="btn btn-danger">
                                                Cancel
                                            </button>
                                            </Link>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>

            </>
        </>

    )
}

export default AddValues